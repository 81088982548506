import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  Collapse,
} from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import { CheckCircle, Cancel } from "@material-ui/icons";

import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import MenuAppBar from "../layout/MenuAppBar";
import FinalSettlement from "./FinalSettlement";
import { parseToHtml } from "../../utils/parser/parseHtml";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  root: {
    marginTop: theme.spacing(8),
    backgroundColor: "#DFDFDF",
    borderRadius: 10,
  },
  plansDiv: {
    marginBottom: theme.spacing(3),
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const Landing = (props) => {
  const {
    OnCleaUp,
    isLoggedIn,
    loading,
    onIsLogInAsync,
    history,
    profile,
    isVerified,
    onGetProfile,
    accounts,
    onGetAccountsAsync,
    getUserFiles,
    files,
    userPlan,
    securityPlan,
    securityFiles,
    onGetSecurityPlan,
    onGetUserSecurityFiles,
    successMessage,
    settlementData,
  } = props;

  const [fileCounts, setFileCounts] = useState({
    type_1: false,
    type_2: false,
    type_3: false,
  });

  const firstLoad = React.useRef(true);
  useEffect(() => {
    if ((!profile && !userPlan) || firstLoad.current) {
      onGetProfile();
    }
  }, [profile, userPlan])

  useEffect(() => {
    if (!accounts || firstLoad.current) {
      onGetAccountsAsync();
    }
  }, [accounts])

  useEffect(() => {
    // if (!files  || firstLoad.current) {
    //   getUserFiles();
    // } else {
    //   let type = {
    //     type_1: files.filter((file) => file.type_id == 1).length > 0,
    //     type_2: files.filter((file) => file.type_id == 2).length > 0,
    //     type_3: files.filter((file) => file.type_id == 3).length > 0,
    //   };
    //   setFileCounts(type);
    // }
  }, [files])

  useEffect(() => {
    if (!securityPlan || firstLoad.current) {
      onGetSecurityPlan();
    }
  }, [securityPlan])

  useEffect(() => {
    if (!securityFiles || firstLoad.current) {
      onGetUserSecurityFiles();
    }
  }, [securityFiles])
  useEffect(() => {
    firstLoad.current = false;
  }, [])

  useEffect(() => {
    return () => {
      OnCleaUp();
    };
  }, [
    OnCleaUp,
    profile,
    userPlan,
    onGetProfile,
    accounts,
    onGetAccountsAsync,
    files,
    getUserFiles,
    securityPlan,
    securityFiles,
    onGetSecurityPlan,
    onGetUserSecurityFiles,
  ]);

  const classes = useStyles();
  return (
    <React.Fragment>
      <MenuAppBar />
      <Container component="main" maxWidth="md">
        <Grid container className={classes.root} spacing={3}>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h4" align="left">
                      Settlement Progress
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                    <Link
                      component={RouterLink}
                      to="/Final-Settlement"
                      variant="body2"
                    >
                      Check
                    </Link>
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    justify="space-between"
                    alignItems="center"
                  >
                    <FinalSettlement />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h4" align="left">
                      Profile
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/Profile" variant="body2">
                      Change
                    </Link>
                  </Grid>
                </Grid>
                {!profile ? null : (
                  <Grid item container xs={12} alignItems="center">
                    <Grid item xs={12} md={4}>
                      <Typography variant="h6" align="left">
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="left"
                      >
                        {profile.firstName +
                          " " +
                          profile.middleName +
                          " " +
                          profile.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="h6" align="left">
                        Email
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="left"
                      >
                        {profile.email}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      sm={6}
                      md={2}
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        {isVerified ? (
                          <CheckCircle style={{ color: green[500] }} />
                        ) : (
                          <Cancel style={{ color: red[500] }} />
                        )}
                      </Grid>
                      <Grid item>
                        {isVerified ? (
                          <Typography
                            variant="subtitle2"
                            style={{ color: green[500] }}
                            align="left"
                          >
                            Email Verified !!
                          </Typography>
                        ) : (
                          <Typography
                            variant="subtitle2"
                            style={{ color: red[500] }}
                            align="left"
                          >
                            Email Not Verified !!
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="h6" align="left">
                        Phone
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="left"
                      >
                        {profile.phone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography variant="h6" align="left">
                        Address
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="left"
                      >
                        {profile.address}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h4" align="left">
                      Cryptopia Info
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link
                      component={RouterLink}
                      to="/AccountsInfo"
                      variant="body2"
                    >
                      Change
                    </Link>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {!accounts ? (
                    <Alert severity="warning">No Cryptopia data saved !!</Alert>
                  ) : (
                    <Table aria-label="cryptopia table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell align="right">
                            User Name
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            Estimated Value($)
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {accounts.map((row) => (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell component="th" scope="row">
                              {row.email}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.userName}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {row.estimated_value}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {false && (
            <Grid item xs={12} md={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    xs={12}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h4" align="left">
                        KYC Status
                      </Typography>
                    </Grid>
                    <Grid item>
                      {false && (
                        <Link
                          component={RouterLink}
                          to="/Uploads"
                          variant="body2"
                        >
                          Change
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                  {false && (
                    <Grid item xs={12}>
                      {!files ? (
                        <Alert severity="warning">
                          No Files Uploaded yet !!
                        </Alert>
                      ) : (
                        <Table aria-label="cryptopia table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>Document Types</StyledTableCell>
                              <StyledTableCell align="right">
                                Uploaded
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow key={1}>
                              <StyledTableCell component="th" scope="row">
                                Identification
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {!fileCounts.type_1 ? (
                                  <Cancel style={{ color: red[500] }} />
                                ) : (
                                  <CheckCircle style={{ color: green[500] }} />
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key={2}>
                              <StyledTableCell component="th" scope="row">
                                Proof of Address
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {!fileCounts.type_2 ? (
                                  <Cancel style={{ color: red[500] }} />
                                ) : (
                                  <CheckCircle style={{ color: green[500] }} />
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key={3}>
                              <StyledTableCell component="th" scope="row">
                                Agreement
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {!fileCounts.type_3 ? (
                                  <Cancel style={{ color: red[500] }} />
                                ) : (
                                  <CheckCircle style={{ color: green[500] }} />
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Box className={classes.justifyCenter}>
                      {settlementData?.is_kyc_done ? (
                        <Box className={classes.paper}>
                          <CheckCircle style={{ color: green[500] }} />{" "}
                          <Typography
                            variant="subtitle2"
                            style={{ color: green[500] }}
                            align="left"
                          >
                            Verified !!
                          </Typography>
                        </Box>
                      ) : (
                        <Box className={classes.paper}>
                          <Cancel style={{ color: red[500] }} />{" "}
                          <Typography
                            variant="subtitle2"
                            style={{ color: red[500] }}
                            align="left"
                          >
                            Check your Cogito profile to complete your KYC
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h4" align="left">
                      Plans
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/Plans" variant="body2">
                      Change
                    </Link>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {!userPlan ? (
                    <Alert severity="warning">
                      No Settlement Plan Selected yet !!
                    </Alert>
                  ) : (
                    <Grid container className={classes.plansDiv}>
                      {/* <Grid item container xs={12} sm={4}>
                                            <Grid item >
                                                <Typography variant="h5" align="left">
                                                    Settlement Plans
                                                </Typography>
                                            </Grid>
                                        </Grid> */}
                      <Grid item container xs={12} sm={12} spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" align="left">
                            Name
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            align="left"
                          >
                            {userPlan.shortDescription}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" align="left">
                            Cost Paid
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            align="left"
                          >
                            €{userPlan.paid}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography variant="h6" align="left">
                            Description
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            align="left"
                          >
                            {parseToHtml({ data: userPlan.longDescription })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {false ? (
            <Grid item xs={12} md={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    container
                    xs={12}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h4" align="left">
                        Security Plan
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Link component={RouterLink} to="/Plans" variant="body2">
                        Change
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {!securityPlan ? (
                      <Alert severity="warning">
                        No Data Claim Plan Selected yet !!
                      </Alert>
                    ) : (
                      <Grid container className={classes.plansDiv}>
                        {/* <Grid item container xs={12} sm={4}>
                                            <Grid item >
                                                <Typography variant="h5" align="left">
                                                    Security Plans
                                                </Typography>
                                            </Grid>
                                        </Grid> */}
                        <Grid item container xs={12} sm={12}>
                          <Grid item xs={12} md={6}>
                            <Typography variant="h6" align="left">
                              Name
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="left"
                            >
                              Register for claim for breach of privacy and for
                              supply of personal data
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="h6" align="left">
                              Cost Paid
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="left"
                            >
                              €
                              {securityPlan.cost_paid
                                ? securityPlan.cost_paid
                                : 0}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="h6" align="left">
                              Uploaded POA Documents
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {!securityFiles ? (
                              <Cancel style={{ color: red[500] }} />
                            ) : (
                              <CheckCircle style={{ color: green[500] }} />
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="h6" align="left">
                              Verified
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            {securityPlan.step_id !== 2 ? (
                              <Cancel style={{ color: red[500] }} />
                            ) : (
                              <CheckCircle style={{ color: green[500] }} />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : null}
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
};
const mapStateToProps = ({ authReducer, userReducer, finalSettlement }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn: authReducer.isLoggedIn,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,

    profile: userReducer.profile,
    isVerified: userReducer.isVerified,
    accounts: userReducer.accounts,
    userPlan: userReducer.userPlan,
    files: userReducer.files,
    securityPlan: userReducer.securityPlan,
    securityFiles: userReducer.securityFiles,

    settlementData: finalSettlement.settlementData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    onGetAccountsAsync: () => dispatch(actionCreator.getAccountsAsync()),
    onGetProfile: () => dispatch(actionCreator.GetProfileAsync()),
    getUserFiles: () => dispatch(actionCreator.getUserFilesAsync()),
    onGetUserSecurityFiles: () =>
      dispatch(actionCreator.getUserSecurityFilesAsync()),
    onGetSecurityPlan: () => dispatch(actionCreator.getSecurityPlanAsync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
