import React, {useState} from 'react';

import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {AppBar,Toolbar,Typography,IconButton,MenuItem,Menu,Drawer,
  List,ListItem,ListItemIcon,ListItemText,Divider} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import {AccountCircle,CloudUpload,CardMembership, AccountBalance,Dashboard, Home} from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import * as actionCreator from "../../store/action/index";

const useStyles = makeStyles((theme) => ({
  navbar:{
    backgroundColor:"#142632",
  },
  root: {
    //flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontWeight: '500',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

const MenuAppBar = (props)=> {
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const {logout,profile,isAdmin,loading,isLoggedIn} = props;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const menuOpen = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    if(!loading){
      setAnchorEl(null);
    }
  };

  return (
    <React.Fragment>
      {!isLoggedIn ? null : (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.navbar}>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} 
            onClick={handleDrawerOpen} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title} >
              CRYPTOPIA
            </Typography>
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                fontSize="large"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
            <List>
              <ListItem button component={RouterLink} to="/" key="landing">
                <ListItemIcon><Home/></ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem button component={RouterLink} to="/Profile" key="profile">
                <ListItemIcon><AccountCircle/></ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem button component={RouterLink} to="/Plans" key="plans">
                <ListItemIcon><CardMembership/></ListItemIcon>
                <ListItemText primary="Plans" />
              </ListItem>
              {/* <ListItem button component={RouterLink} to="/Uploads" key="uploads">
                <ListItemIcon><CloudUpload/></ListItemIcon>
                <ListItemText primary="Uploads" />
              </ListItem> */}
              <ListItem button component={RouterLink} to="/AccountsInfo" key="AccountsInfo">
                <ListItemIcon><AccountBalance/></ListItemIcon>
                <ListItemText primary="Cryptopia Accounts Info" />
              </ListItem>
              {!isAdmin ? null:
                <ListItem button component={RouterLink} to="/Admin" key="Admin">
                  <ListItemIcon><Dashboard/></ListItemIcon>
                  <ListItemText primary="Admin Dashboard" />
                </ListItem>
              }
            </List>
        </Drawer>
        <div className={classes.drawerHeader} />
      </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = ({ authReducer,userReducer,adminReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn :authReducer.isLoggedIn,

    isAdmin:adminReducer.isAdmin,

    profile : userReducer.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actionCreator.OnLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuAppBar)

