import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import AddDocs from "../microcomponents/AddDocs";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const EditCryptopiaDialog = (props) => {
  const { status, editItem, loading, onDialogClose, confirmEdit } = props;

  const [editUser, setEditUser] = useState({
    id: 0,
    email: "",
    userName: "",
    estimated_value: 0.0,
    file: [],
    fileName: [],
    deleteFile: [],
  });
  const [errorMessages, setErrorMessages] = useState({
    emailError: "",
    userNameError: "",
  });
  const [validData, setvalidData] = useState({
    validUserName: false,
    validEmail: false,
    validValue: false,
  });

  useEffect(() => {
    if (editItem) {
      setEditUser(editItem);
      setErrorMessages({
        emailError: "",
        userNameError: "",
      });
      setvalidData({
        validUserName: false,
        validEmail: false,
      });
    }
  }, [editItem]);

  const validateField = (field, value) => {
    let errorMessagesCopy = { ...errorMessages };
    let validDataCopy = { ...validData };

    if (field === "userName") {
      if (value.trim().length === 0) {
        errorMessagesCopy.userNameError = "This field is required";
        validDataCopy.validUserName = false;
      } else {
        validDataCopy.validUserName = true;
        errorMessagesCopy.userNameError = "";
      }
    } else if (field === "email") {
      let emailRegEX =
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (value.trim().length === 0) {
        errorMessagesCopy.emailError = "This field is required";
        validDataCopy.validEmail = false;
      } else if (!emailRegEX.test(value.trim())) {
        errorMessagesCopy.emailError = "please enter a valid email address";
        validDataCopy.validEmail = false;
      } else {
        errorMessagesCopy.emailError = "";
        validDataCopy.validEmail = true;
      }
    } else if (field === "estimated_value") {
      validDataCopy.validValue = true;
    }
    setErrorMessages(errorMessagesCopy);
    setvalidData(validDataCopy);
  };

  const onInputValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let userCopy = { ...editUser };
    userCopy[name] = value;
    setEditUser(userCopy);
    validateField(name, value);
  };

  const submitHandle = (e) => {
    e.preventDefault();
    if (
      validData.validUserName ||
      validData.validEmail ||
      validData.validValue ||
      editUser?.file?.length ||
      editUser?.deleteFile?.length
    ) {
      confirmEdit(editUser);
    }
    console.log("editUser: ", editUser)
  };
  useEffect(() => {
    setEditUser(editData => ({ ...editData, file: [], fileName: [] }))
  }, [status])
  return (
    <Dialog
      onClose={onDialogClose.bind(this, false)}
      aria-labelledby="customized-dialog-title"
      open={status}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={onDialogClose.bind(this, false)}
      >
        Edit
      </DialogTitle>
      <form onSubmit={submitHandle} method="post">
        <DialogContent dividers>
          <Grid
            container
            justify="space-evenly"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12} md={6}>
              <TextField
                error={errorMessages.emailError.length > 0}
                helperText={errorMessages.emailError}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                type="email"
                name="email"
                autoComplete="email"
                value={editUser.email}
                onChange={onInputValueChanged}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                error={errorMessages.userNameError.length > 0}
                helperText={errorMessages.userNameError}
                variant="outlined"
                required
                fullWidth
                id="userName"
                label="User Name"
                name="userName"
                autoComplete="userName"
                value={editUser.userName}
                onChange={onInputValueChanged}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="estimated_value"
                label="Estimated Value"
                type="number"
                name="estimated_value"
                autoComplete="estimated_value"
                value={editUser.estimated_value}
                onChange={onInputValueChanged}
              />
            </Grid>
            <Grid item xs={12}>
              <AddDocs
                selectId={"edit"}
                cryptopiaId={editItem?.id}
                setBlob={setEditUser}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Save changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCryptopiaDialog;
