export const getStatusFromId = (statusList, id) => {
  if (statusList) {
    let status = statusList.filter((statusData) => statusData.id === id);
    return status;
  }
  return {};
};

export const getStatusFromStatusName = (statusList, short_desc) => {
  if (statusList) {
    let status = statusList.filter(
      (statusData) => statusData.short_desc === short_desc
    );
    return status;
  }
  return {};
};

export const getStatusLookUp = (statusList) => {
  if (statusList) {
    let lookup = {};
    statusList.map((statusListData) => {
      lookup[statusListData.id] = statusListData.short_desc;
    });
    return lookup;
  }
  return {};
};
