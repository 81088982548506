import React from "react";
import "./Spinner.css";

const spinner = (props) => {
  return (
    <div
      className="Loader"
      style={{ height: props.size, width: props.size, margin: props.margin }}
    >
      Loading...
    </div>
  );
};

export default spinner;
