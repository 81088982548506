import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Grid,
  Collapse,
  InputLabel,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import * as actionCreator from "../../../store/action/index";
import UserTable from "./userTable";
import RetriveDocs from "./retriveDocs";
import MailButton from "./mailButton";
import SendMail from "./SendMail";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
  },
}));

const SearchAndAction = (props) => {
  const {
    loading,
    errorMessage,
    successMessage,
    adminData,
    getUserData,
    statusTypes,
    userData,
    onSetKycStatus,
    resetUserPassword,
    retriveDocuments,
  } = props;

  const [mailText, setMailText] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [onlyUser, setOnlyUser] = useState(null);
  const [mailUsers, setMailUsers] = useState("");
  const [kycStatus, setKycStatus] = useState(0);
  const [password, setPassword] = useState({
    password: "",
    rePassword: "",
  });

  const onValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "mailText") {
      setMailText(value);
    } else if (name == "kycStatus") {
      setKycStatus(value);
    } else if (name == "password" || name == "rePassword") {
      let passwordCopy = { ...password };
      passwordCopy[name] = value;
      setPassword(passwordCopy);
    }
  };

  const searchHandler = (e) => {
    e && e.preventDefault && e.preventDefault();
    getUserData(mailText);
  };

  const onUserSelect = (users) => {
    let userIds = [];
    let mailIds = "";
    let onlySelectedUser = null;
    if (users.length == 1) {
      onlySelectedUser = users[0];
    }

    users.forEach((user, index) => {
      userIds.push(user.id);
      setOnlyUser(users[0]);

      if (index == 0) {
        mailIds = user.email;
      } else {
        mailIds = mailIds + "," + user.email;
      }
    });

    setMailUsers(mailIds);
    setOnlyUser(onlySelectedUser);
    setSelectedUsers(userIds);
  };

  const kycUpdateHandler = (e) => {
    e.preventDefault();
    if (kycStatus) {
      onSetKycStatus({
        userList: selectedUsers,
        kycStatus: kycStatus,
      });
    }
  };

  const resetPasswordHandler = (e) => {
    e.preventDefault();
    if (password.password === password.rePassword) {
      resetUserPassword({
        id: onlyUser.id,
        email: onlyUser.email,
        password: password.password,
      });
    } else {
      alert("Passwords don't match!!");
    }
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        <form onSubmit={searchHandler} method="post">
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            xs={12}
            spacing={1}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                name="mailText"
                label="Search By Email"
                id="mailText"
                autoComplete="mailText"
                fullWidth
                size="small"
                placeholder="All Emails"
                value={mailText}
                onChange={onValueChanged}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="default"
                margin="dense"
                size="large"
                disabled={loading}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" gutterBottom>
                Search without entry to get all the data.
              </Typography>
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12}>
          <UserTable userData={userData} onUserSelect={onUserSelect} searchHandler={searchHandler} />
        </Grid>

        <Grid item xs={12}>
          <Collapse in={successMessage.length > 0}>
            <Alert severity="success">{successMessage}</Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={errorMessage.length > 0}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>
        </Grid>

        {false && <form onSubmit={kycUpdateHandler} method="post">
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            spacing={1}
            className={classes.formControl}
          >
            <Grid item xs={12}>
              <Typography variant="h6">Change User KYC Status</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                variant="outlined"
                margin="dense"
                id="kycStatus"
                name="kycStatus"
                value={kycStatus}
                onChange={onValueChanged}
              >
                <MenuItem value={0}>Select</MenuItem>
                {statusTypes &&
                  statusTypes.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.short_desc}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="default"
                margin="dense"
                size="large"
                disabled={loading || !selectedUsers.length}
              >
                Change
              </Button>
            </Grid>
          </Grid>
        </form>}

        <RetriveDocs user={onlyUser} />

        <form onSubmit={resetPasswordHandler} method="post">
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            spacing={1}
            className={classes.formControl}
          >
            <Grid item xs={12}>
              <Typography variant="h6">Reset Password</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="subtitle2"
                align="justify"
                color="textSecondary"
              >
                Password must contain at least 8 characters, including a number
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                variant="outlined"
                margin="normal"
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="password"
                fullWidth
                required
                value={password.password}
                onChange={onValueChanged}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                variant="outlined"
                margin="normal"
                name="rePassword"
                label="Confirm Password"
                type="password"
                id="rePassword"
                autoComplete="password"
                fullWidth
                required
                value={password.rePassword}
                onChange={onValueChanged}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="default"
                margin="dense"
                size="large"
                disabled={loading || selectedUsers.length != 1}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>

        <SendMail />

        <MailButton email={mailUsers} />
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authReducer, adminReducer }) => {
  return {
    loading: authReducer.loading,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,

    userData: adminReducer.userData,
    statusTypes: adminReducer.statusTypes,
    adminData: adminReducer.adminDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (email) => dispatch(actionCreator.getUserData(email)),
    onSetKycStatus: (data) => dispatch(actionCreator.setKycStatus(data)),
    resetUserPassword: (data) =>
      dispatch(actionCreator.resetUserPassword(data)),
    retriveDocuments: (data) => dispatch(actionCreator.retriveDocuments(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchAndAction);
