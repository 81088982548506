import {
    Box,
    Grid,
    Typography,
    Button,
    TextField,
    makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import image1 from "../../asset/image/stepperImage/Step1.1.png"
import image2 from "../../asset/image/stepperImage/Step1.2.png"
import image3 from "../../asset/image/stepperImage/Step1.3.jpg"
import image4 from "../../asset/image/stepperImage/Step1.4.jpg"
import image5 from "../../asset/image/stepperImage/Step1.5.png"
import image6 from "../../asset/image/stepperImage/Step1.6.png"
import image7 from "../../asset/image/stepperImage/Step1.7.png"
import image8 from "../../asset/image/stepperImage/Step1.8.png"
import ImageSteps from "./ImageSteps";
import StepperImage from "./StepperImage";
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
}));
const imageList = [image1, image2, image3, image4, image5, image6, image7, image8]
const RegistrationContent = ({ close, finalSettlementCheck, step, profile }) => {
    const classes = useStyles();

    return (
        <>
            <Box className="list-template">
                <ul>
                    <li><strong>Welcome to Cryptopia!</strong> Let’s get started! First off you need to create a Cogito account. Click the link and you’ll be directed to the sign-up section <a href="https://app.principalityofcogito.com/signup" target={"_blank"}>https://app.principalityofcogito.com/signup</a></li>
                    <li>Enter your email address and then click <strong>“sign up”</strong>.</li>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}><StepperImage image={image1} /></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image2} /></Grid>
                    </Grid>
                    <li>Then go to your email <strong>“inbox”</strong>. You will see the verification email there. Open it and click the link provided in the email.</li>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}><StepperImage image={image3} /></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image4} /></Grid>
                    </Grid>
                    <li>You will then be led to a page where you can create your own <strong>Cogito account for free</strong>. Just fill up the questions there and we’re off to the next step.</li>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}><StepperImage image={image5} /></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image6} /></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image7} /></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image8} /></Grid>
                    </Grid>
                </ul>
                {/* <ImageSteps imageList={imageList} /> */}
            </Box>
        </>
    );
};
const mapStateToProps = ({ }) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegistrationContent);
