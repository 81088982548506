import { Box, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx";
import React from "react"

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    },
    imageBox: {
        margin: "20px auto"
    }
}));

const ImageSteps = ({ imageList }) => {
    const classes = useStyles()
    return (
        <Box className={clsx(classes.imageContainer)}>
            {imageList.map((data, index) => <Box key={index} className={clsx(classes.imageContainer, classes.imageBox)}>
                <Typography className={clsx(classes.imageBox)}>{`Step: ${index + 1}`}</Typography>
                <a href={data} target="_blank" className={clsx(classes.imageContainer)}><img src={data} style={{ width: "90%", maxWidth: 600 }} /></a>
            </Box>)}
        </Box>
    )
}

export default ImageSteps