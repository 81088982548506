import React,{ useState,useEffect,forwardRef} from 'react';
import { connect } from "react-redux";
import {Button,TextField,Grid} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import EmailIcon from '@material-ui/icons/Email';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme,makeStyles } from '@material-ui/core/styles';

import * as actionCreator from "../../../store/action/index";


const useStyles = makeStyles((theme) => ({
    margin: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(12),
    },
  }));

const MailButton = (props)=>{

  const {email,sendMailToUsers} = props;
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [mailTo, setMailTo] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(
    () => {
        setMailTo(email); 
        return ()=>{
            setText("");
            setMailTo("");
          }
    },
    [email]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if(name == "text"){
        setText(value);
    }
    else if(name == "mailTo"){
        setMailTo(value);
    }
  }
  const handleSend = () => {
    if(text.length && mailTo.length){
        sendMailToUsers({
            to : mailTo,
            text : text
        })
    }
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <div>
      <Fab color="secondary" aria-label="add" className={classes.margin} onClick={handleClickOpen}>
        <EmailIcon />
      </Fab>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Send Mail To Users"}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-textarea"
                        label="To"
                        placeholder="User Email"
                        name='mailTo'
                        multiline
                        fullWidth
                        variant="outlined"
                        value={mailTo}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Mail Body"
                        name='text'
                        multiline
                        fullWidth
                        rows={4}
                        value={text}
                        variant="outlined"
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSend} color="primary" autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ authReducer,adminReducer }) => {
    return {
        loading: authReducer.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendMailToUsers: (data) => dispatch(actionCreator.sendMailToUsers(data)),
    };
};
  
  export default connect(mapStateToProps, mapDispatchToProps)(MailButton)