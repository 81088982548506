import * as React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Paper,
  Container,
  makeStyles,
  Box,
  Typography,
  StepContent,
  ButtonBase,
} from "@material-ui/core";
import { QontoConnector, QontoStepIcon } from "./microcomponents/SecurityPlan";
import MenuAppBar from "../layout/MenuAppBar";
import clsx from "clsx";
import * as actionCreator from "../../store/action/index";
import { connect } from "react-redux";
import Skeleton from "@material-ui/lab/Skeleton";
import SettlementDialog from "./SettlementDialog";
import MakePaymentContent from "./MakePaymentContent";
import KycContent from "./KycContent";
import RegistrationContent from "./RegistrationContent";

const useStyle = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center",
  },
  instructionFont: {
    opacity: 0.6,
    fontSize: 13,
    textAlign: "center",
    marginTop: 10,
  },
  moreFont: {
    opacity: 0.6,
    fontSize: 13,
    fontWeight: 600,
    textAlign: "center",
    marginTop: 10,
    color: "#784af4",
  },
}));

const FinalSettlement = ({
  checking,
  step,
  finalSettlementCheck,
  profile,
  onGetProfile,
}) => {
  const classes = useStyle();
  const [DialogOpen, setDialogOpen] = React.useState(false);
  let steps = [
    {
      label: "Register with Cogito",
      desc: "Welcome to Cryptopia! Let’s get started! Let’s make a Cogito account for you.",
      detail: <RegistrationContent close={setDialogOpen} />,
    },
    {
      label: "KYC",
      desc: "Let’s now finish with your KYC (Know Your Customer).",
      detail: <KycContent close={setDialogOpen} />,
    },
    {
      label: "Make Payment",
      desc: "Let’s now process your chosen plan.",
      detail: <MakePaymentContent close={setDialogOpen} />,
    },
    {
      label: "Registration Complete",
      desc: "Congratulations! You have now officially completed your registration! We look forward to serving and working with you."
    },
  ];
  // React.useEffect(() => {
  //   if (!profile) {
  //     onGetProfile();
  //   }
  // }, [profile]);
  React.useEffect(() => {
    if (profile) finalSettlementCheck(profile.email);
  }, [profile]);
  return (
    <>
      <SettlementDialog
        status={DialogOpen}
        onDialogClose={setDialogOpen}
        title={steps[step]?.label}
        description={steps[step]?.detail}
      />
      {/* <MenuAppBar /> */}
      <Container component="main" maxWidth="md">
        <Paper className={classes.paper}>
          {checking ? (
            <Skeleton variant="rect" width="100%" height={100} />
          ) : (
            <>
              {/* <Box className={clsx(classes.justifyCenter)}>
                <Typography variant="h5" align="left" display="inline">
                  Final Settlement Status
                </Typography>
              </Box> */}
              <Stepper
                alternativeLabel
                activeStep={step < steps.length - 1 ? step : step + 1}
                connector={<QontoConnector />}
              >
                {steps.map((stepData, index) => (
                  <Step key={index + 1}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {stepData.label}
                      {index <= step ? (
                        <Typography className={clsx(classes.instructionFont)}>
                          {stepData.desc}
                        </Typography>
                      ) : null}
                      {index == step && steps[index]?.detail ? (
                        <ButtonBase
                          className={clsx(classes.moreFont)}
                          onClick={() => setDialogOpen(!DialogOpen)}
                        >
                          Detail
                        </ButtonBase>
                      ) : null}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};
const mapStateToProps = ({ finalSettlement, userReducer }) => {
  return {
    checking: finalSettlement.checking,
    step: finalSettlement.step,
    profile: userReducer.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    finalSettlementCheck: (email) =>
      dispatch(actionCreator.finalSettlementCheck(email)),
    onGetProfile: () => dispatch(actionCreator.GetProfileAsync()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FinalSettlement);
