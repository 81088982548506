export const SET_LOADING = "SET_LOADING";
export const REGISTER = "REGISTER";
export const LOGIN = "LOGIN";
export const SET_STATUS = "SET_STATUS";
export const IS_LOG_IN = "IS_LOG_IN";
export const LOGOUT = "LOGOUT";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const GET_PLANS = "GET_PLANS";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_FILES = "UPDATE_FILES";
export const UPDATE_VERIFIED = "UPDATE_VERIFIED";
export const SET_SELECTED_PLAN = "SET_SELECTED_PLAN";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_SECURITYPLAN = "SET_SECURITYPLAN";
export const UPDATE_SECURITY_FILES = "UPDATE_SECURITY_FILES";
export const UPDATE_SETTLEMENT_STATUS = "UPDATE_SETTLEMENT_STATUS";
export const CRYPTOPIA_FILES = "CRYPTOPIA_FILES";

export const SET_ADMIN = "SET_ADMIN";
export const GET_ADMIN = "GET_ADMIN";
export const GET_USERS = "GET_USERS";
export const GET_ADMINS = "GET_ADMINS";
export const GET_SECURITY_USERS = "GET_SECURITY_USERS";
export const SET_FILE_LIST = "SET_FILE_LIST";
export const SET_STATUS_LIST = "SET_STATUS_LIST";
export const SET_COURT_LIST = "SET_COURT_LIST";
