import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../asset/utility";

const initialState = {
  loading: false,
  isLoggedIn:false,
  checking:true,
  token:null,
  isRegistered: false,
  successMessage:"",
  error: null,
  errorMessage: ""
};

//# HERE IS THE REDUCER OR CASE'S
const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LOADING:
      return setLoading(state);
    case actionTypes.REGISTER:
      return register(state, payload);
    case actionTypes.LOGIN:
      return logIn(state, payload);
    case actionTypes.SET_STATUS:
      return setStatus(state, payload);
    case actionTypes.IS_LOG_IN:
      return isLogIn(state, payload);
    case actionTypes.LOGOUT:
      return logout(state);
    default:
      return state;
  }
};

/*--- CASES ARE HERE DEFINED */
// UTILITY FUNCTION CALCULATE UTILIZATION
const setLoading = (state) => {
  return updateObject(state, {
    loading: true
  });
};
const register = (state,data) => {
  const { status, successMessage , errorMessage } = data;
  return updateObject(state, {
    loading: false,
    isRegistered: status,
    error: !status,
    successMessage :successMessage,
    errorMessage: errorMessage
  });
};
const logIn = (state,data) => {
  const { status, successMessage , errorMessage, token } = data;
  return updateObject(state, {
    loading: false,
    isLoggedIn:status,
    token:token,
    error: !status,
    successMessage:successMessage,
    errorMessage: errorMessage
  });
};
const setStatus = (state,data) => {
  const { error, successMessage , errorMessage } = data;
  return updateObject(state, {
    loading: false,
    error: error || null,
    successMessage:successMessage || "",
    errorMessage: errorMessage || ""
  });
};

const isLogIn = (state,data) => {
  const {status} = data;
  return updateObject(state, {
    isLoggedIn:status,
    checking:false
  });
};

const logout = (state,data) => {
  return updateObject(state, {
    loading: false,
    isLoggedIn:false,
    token:null,
    error: null,
    successMessage:"",
    errorMessage: ""
  });
};

export default authReducer;
