import { combineReducers } from "redux";
import auth from "./authReducer";
import user from "./userReducer";
import admin from "./adminReducer";
import finalSettlement from "./finalSettlementReducer";
const rootReducer = combineReducers({
  authReducer: auth,
  userReducer: user,
  adminReducer: admin,
  finalSettlement: finalSettlement,
});

export default rootReducer;
