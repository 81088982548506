import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import { parseToHtml } from "../../../utils/parser/parseHtml";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  itemHolder: {
    marginTop: theme.spacing(3),
  },
  rightSide: {
    marginLeft: "auto",
  },
  includedPlanText: {
    fontSize: 12,
    fontWeight: 600,
  },
  descriptionContainer: {
    maxHeight: 220,
    overflowY: "scroll"
  }
}));

const PlanList = (props) => {
  const classes = useStyles();
  const { plans, onSelect, available, userPlan } = props;
  // let planName = "Already included in";
  return (
    <Grid
      container
      alignItems="stretch"
      justify="flex-start"
      className={classes.itemHolder}
      spacing={1}
    >
      {plans &&
        plans.map((item, index) => {
          // planName +=
          //   plans.length - 1 > index
          //     ? `${
          //         index > 0 ? (plans.length - 2 > index ? ", " : " and ") : ""
          //       } ${item.short_description}`
          //     : "";
          return (
            <Grid item xs={12} sm={4} key={item.id}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6">{item.short_description}</Typography>
                  <Typography variant="body2" component="p" className={clsx(classes.descriptionContainer,"scrollbar")}>
                    {parseToHtml({ data: item.long_description })}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  {/* <Typography variant="h6" color="textSecondary">
                      €{item.cost}
                    </Typography> */}

                  {item.can_select ?
                    userPlan?.id == item.id ?
                      <Typography>Already selected.</Typography> :
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        className={classes.rightSide}
                        disabled={available}
                        onClick={onSelect.bind(this, {
                          planId: item.id,
                          planName: item.short_description,
                          planDescription: item.long_description,
                          costPaid: item.cost,
                        })}
                      >
                        select
                      </Button> : null
                    // <Typography>Already included in all Plans.</Typography>
                  }
                </CardActions>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default PlanList;
