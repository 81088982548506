import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  centerText: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: 18
  }
}));

const SettlementDialog = (props) => {
  const { status, onDialogClose, title, description } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={status}
      onClose={onDialogClose.bind(this, false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { minWidth: 320 } }}
    >
      <DialogTitle id="alert-dialog-title" className={clsx(classes.centerText, classes.title)}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onDialogClose.bind(this, false)}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography variant="h6" color="textSecondary">
          {description}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default SettlementDialog;
