import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Collapse,
  Paper,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom";
import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../asset/api";
import Logo from "../layout/Logo";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const Register = (props) => {
  const history = useHistory();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    re_password: "",
    toVerify: true,
  });
  const [errorMessages, setErrorMessages] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    passwordError: "",
    rePasswordError: "",
  });
  const [validData, setvalidData] = useState({
    validFirstName: false,
    validLastName: false,
    validEmail: false,
    validPassword: false,
    validRePassword: false,
  });
  const [showForm, setShowForm] = useState(false);

  const {
    isLoggedIn,
    OnCleaUp,
    onIsLogInAsync,
    loading,
    errorMessage,
    successMessage,
  } = props;

  useEffect(() => {
    return () => {
      OnCleaUp();
    };
  }, [OnCleaUp]);

  const captchaHandle = (value) => {
    setShowForm(true);
  };

  const validateField = (field, value) => {
    let errorMessagesCopy = { ...errorMessages };
    let validDataCopy = { ...validData };

    if (field === "firstName" || field === "lastName") {
      if (value.trim().length === 0) {
        if (field === "firstName") {
          errorMessagesCopy.firstNameError = "This field is required";
          validDataCopy.validFirstName = false;
        } else {
          errorMessagesCopy.lastNameError = "This field is required";
          validDataCopy.validLastName = false;
        }
      } else if (!/^[A-Za-z ]+$/.test(value.trim())) {
        if (field === "firstName") {
          errorMessagesCopy.firstNameError =
            "Name should contain only alphabets";
          validDataCopy.validFirstName = false;
        } else {
          errorMessagesCopy.lastNameError =
            "Name should contain only alphabets";
          validDataCopy.validLastName = false;
        }
      } else {
        if (field === "firstName") {
          validDataCopy.validFirstName = true;
          errorMessagesCopy.firstNameError = "";
        } else {
          validDataCopy.validLastName = true;
          errorMessagesCopy.lastNameError = "";
        }
      }
    } else if (field === "email") {
      let emailRegEX =
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (value.trim().length === 0) {
        errorMessagesCopy.emailError = "This field is required";
        validDataCopy.validEmail = false;
      } else if (!emailRegEX.test(value.trim())) {
        errorMessagesCopy.emailError = "please enter a valid email address";
        validDataCopy.validEmail = false;
      } else {
        errorMessagesCopy.emailError = "";
        validDataCopy.validEmail = true;
      }
    } else if (field === "password") {
      let passwordRegEX = /(?=.*\d).{8,}/;
      if (value.trim().length === 0) {
        errorMessagesCopy.passwordError = "This field is required";
        validDataCopy.validPassword = false;
      } else if (!passwordRegEX.test(value.trim())) {
        errorMessagesCopy.passwordError =
          "Password must contain at least 8 characters, including a number";
        validDataCopy.validPassword = false;
      } else {
        errorMessagesCopy.passwordError = "";
        validDataCopy.validPassword = true;
      }
    } else if (field === "re_password") {
      if (value !== user.password) {
        errorMessagesCopy.rePasswordError = "Passwords don't match";
        validDataCopy.validRePassword = false;
      } else {
        errorMessagesCopy.rePasswordError = "";
        validDataCopy.validRePassword = true;
      }
    }
    setErrorMessages(errorMessagesCopy);
    setvalidData(validDataCopy);
  };

  const onInputValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let userCopy = { ...user };
    userCopy[name] = value;
    setUser(userCopy);
    validateField(name, value);
  };

  const registerHandler = (e) => {
    e.preventDefault();
    if (
      validData.validFirstName &&
      validData.validLastName &&
      validData.validEmail &&
      validData.validPassword &&
      validData.validRePassword
    ) {
      props.onRegisterAsync(user);
    }
  };

  const classes = useStyles();

  useEffect(() => {
    if (successMessage.length > 0) {
      localStorage.setItem("loginEmail", user.email);
      localStorage.setItem("loginPassword", user.password);
      setTimeout(() => history.push("/login"), 3000);
    }
  }, [successMessage]);

  return (
    <Container component="main" maxWidth="xs">
      <Grid container alignItems="flex-end" justify="center">
        <Logo />
      </Grid>
      <Paper className={classes.paper}>
        {!showForm ? (
          <ReCAPTCHA
            sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={captchaHandle}
          />
        ) : (
          <form
            className={classes.form}
            onSubmit={registerHandler}
            method="post"
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.title}
                >
                  Register
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errorMessages.firstNameError.length > 0}
                  helperText={errorMessages.firstNameError}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={user.firstName}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errorMessages.lastNameError.length > 0}
                  helperText={errorMessages.lastNameError}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={user.lastName}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errorMessages.emailError.length > 0}
                  helperText={errorMessages.emailError}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={user.email}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errorMessages.passwordError.length > 0}
                  helperText={errorMessages.passwordError}
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={user.password}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errorMessages.rePasswordError.length > 0}
                  helperText={errorMessages.rePasswordError}
                  variant="outlined"
                  required
                  fullWidth
                  name="re_password"
                  label="confirm"
                  type="password"
                  id="re_password"
                  autoComplete="current-password"
                  value={user.re_password}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="subtitle2"
                  align="justify"
                  color="textSecondary"
                >
                  A verification mail will be sent to the above email address.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={errorMessage.length > 0}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Collapse>
                <Collapse in={successMessage.length > 0}>
                  <Alert severity="success">{successMessage}</Alert>
                </Collapse>
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end" spacing={1}>
              <Grid item xs={12} sm={6}>
                <Link component={RouterLink} to="/login" variant="body2">
                  Log In Instead
                </Link>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={props.loading}
                  //className={classes.submit}
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn: authReducer.isLoggedIn,
    registrationStatus: authReducer.isRegistered,
    error: authReducer.error,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRegisterAsync: (userData) =>
      dispatch(actionCreator.RegisterAsync(userData)),
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
