import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import { parseToHtml } from '../../../utils/parser/parseHtml';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));


const PaymentDialog = (props) => {

  const { status, plan, onDialogClose, OnConfirmPlan } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={status}
      onClose={onDialogClose.bind(this, false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Select this plan?
      </DialogTitle>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onDialogClose.bind(this, false)}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Typography variant="h6">
          {plan.planName}
        </Typography>
        <Typography variant="body2" component="p">
          {parseToHtml({ data: plan.planDescription })}
        </Typography>
        {/* <Typography variant="h6" color="textSecondary">
                €{plan.costPaid}
              </Typography> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose.bind(this, false)} variant="contained" color="secondary">
          Close
        </Button>
        <Button onClick={OnConfirmPlan.bind(this)} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentDialog;
