import * as actionTypes from "./actionTypes";
import axios from "axios";

import qs from "qs";
import { API, setWithExpiry, getWithExpiry } from "../../asset/api";

const setLoading = () => {
  return {
    type: actionTypes.SET_LOADING,
  };
};
const setStatus = (data) => {
  return {
    type: actionTypes.SET_STATUS,
    payload: data,
  };
};

const updateProfile = (data) => {
  return {
    type: actionTypes.UPDATE_PROFILE,
    payload: data,
  };
};

export const VerifyUserAsync = ({ email }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      email: email,
    };

    API.post("/Confirm", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage:
              "Couldn't send confirmation, refresh and try again !!",
          })
        );
      });
  };
};

const updatePlan = (data) => {
  return {
    type: actionTypes.UPDATE_PLAN,
    payload: data,
  };
};

const updateVerified = (data) => {
  return {
    type: actionTypes.UPDATE_VERIFIED,
    payload: data,
  };
};

export const GetProfileAsync = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
    };
    API.post("/GetProfile", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const {
          status,
          user,
          userPlan,
          verified,
          successMessage,
          errorMessage,
        } = res.data;
        dispatch(
          updateProfile({
            user: user,
          })
        );
        dispatch(
          updatePlan({
            userPlan: userPlan,
          })
        );
        dispatch(
          updateVerified({
            isVerified: verified,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          updateProfile({
            user: null,
          })
        );
        dispatch(
          updatePlan({
            userPlan: null,
          })
        );
        dispatch(
          setStatus({
            errorMessage: "Couldn't Fetch User Profile !!",
          })
        );
      });
  };
};

export const getUploadedFiles = (id) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      cryptopiaId: id,
    };
    API.post("/GetUserFiles", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { files, errorMessage } = res.data;
        dispatch({ type: actionTypes.CRYPTOPIA_FILES, payload: { files } });
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch({ type: actionTypes.CRYPTOPIA_FILES, payload: { files: [] } });
        dispatch(
          setStatus({
            errorMessage: "Couldn't Fetch User Files !!",
          })
        );
      });
  };
};

export const UpdateProfileAsync = ({
  email,
  firstName,
  middleName,
  lastName,
  address,
  city,
  country,
  phone,
}) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    const currentUser = getState().userReducer.profile;
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      email: email,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      address: address,
      city: city,
      country: country,
      phone: phone,
    };
    API.post("/UpdateUser", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, user, successMessage, errorMessage } = res.data;
        dispatch(
          updateProfile({
            user: user,
          })
        );
        dispatch(
          setStatus({
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err.response);
        dispatch(
          updateProfile({
            user: currentUser,
          })
        );
        dispatch(
          setStatus({
            errorMessage: "Couldn't Update Profile !",
          })
        );
      });
  };
};

const getPlans = (data) => {
  return {
    type: actionTypes.GET_PLANS,
    payload: data,
  };
};

export const GetPlansAsync = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
    };
    API.post("/GetPlans", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, plan, successMessage, errorMessage } = res.data;
        dispatch(
          getPlans({
            plan: plan,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err.response);
        dispatch(
          getPlans({
            plan: null,
          })
        );
        dispatch(
          setStatus({
            errorMessage: "Couldn't get Plans !",
          })
        );
      });
  };
};

export const SelectPlanAsync = ({ planId, costPaid }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      planId: planId,
      costPaid: costPaid,
    };
    API.post("/SelectPlan", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, userPlan, successMessage, errorMessage } = res.data;
        dispatch(
          updatePlan({
            userPlan: userPlan,
          })
        );
        dispatch(
          setStatus({
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err.response);
        dispatch(
          updatePlan({
            userPlan: null,
          })
        );
        dispatch(
          setStatus({
            errorMessage: "Couldn't select Plan !",
          })
        );
      });
  };
};

const setSelectedPlan = (plan) => {
  return {
    type: actionTypes.SET_SELECTED_PLAN,
    payload: plan,
  };
};

export const SetSelectedPlan = (plan) => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    dispatch(setSelectedPlan({ plan: plan }));
    dispatch(setStatus({}));
  };
};

const setUserFiles = (data) => {
  return {
    type: actionTypes.UPDATE_FILES,
    payload: data,
  };
};

export const UploadFileAsync = ({ file, type }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token") || getState().authReducer.token;
    let data = new FormData();
    data.append("file", file);
    data.append("type", type);
    data.append("token", storedToken);
    dispatch(setLoading());
    API.post("/UploadFile", data, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        const { status, successMessage, errorMessage, file } = res.data;
        dispatch(
          setStatus({
            error: !status,
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
        dispatch(getUserFilesAsync());
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            status: false,
            successMessage: "",
            errorMessage: "Couldn't upload file,Please refresh and try again",
          })
        );
      });
  };
};

export const getUserFilesAsync = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
    };

    API.post("/GetFiles", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, files } = res.data;
        dispatch(
          setUserFiles({
            files: files,
          })
        );
        dispatch(
          setStatus({
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Couldn't upload image,Please refresh and try again",
          })
        );
      });
  };
};

const setAccounts = (data) => {
  return {
    type: actionTypes.SET_ACCOUNTS,
    payload: data,
  };
};

export const getAccountsAsync = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
    };

    API.post("/GetAccounts", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, accounts } = res.data;
        dispatch(
          setAccounts({
            accounts: accounts,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage:
              "Couldn't fetch accounts,Please refresh and try again",
          })
        );
      });
  };
};

export const editAccountsAsync = ({
  id,
  email,
  userName,
  estimated_value,
  file,
  fileName,
  deleteFile,
}) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      id: id,
      email: email,
      userName: userName,
      estimated_value: estimated_value,
    };

    let response = await API.post("/EditAccount", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        if (status) {
          dispatch(getAccountsAsync());
          dispatch(
            setStatus({
              successMessage: successMessage,
              errorMessage: errorMessage,
            })
          );
        }
        return res;
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Couldn't edit account,Please refresh and try again",
          })
        );
      });
    if (id && file && file.length) {
      let cryptopia_info_id = id;
      await Promise.all(file.map((file, index) => new Promise((response, reject) => {
        let data = new FormData();
        data.append("cryptopia_info_id", cryptopia_info_id);
        data.append("file", file);
        data.append("fileName", fileName[index]);
        data.append("token", storedToken);
        dispatch(setLoading());
        API.post(
          "/AddAccountFile",
          data,
          {
            "Content-Type": "multipart/form-data",
            headers: { "token": storedToken }
          }
        ).then((res) => {
          response(res)
        }).catch(err => reject())
      })))
      dispatch(getAccountsAsync());
      dispatch(
        setStatus({
          successMessage: "Upload Done",
          errorMessage: "",
        })
      );
    }
    if (deleteFile && deleteFile.length) {
      await API.post(
        "/DeleteAccountFile",
        qs.stringify({
          deleteFile: deleteFile,
          token: storedToken,
        }),
        {
          withCredentials: false,
        }
      )
        .then((res) => {
          const { status, successMessage, errorMessage } = res.data;
          if (status) {
            dispatch(getAccountsAsync());
            dispatch(
              setStatus({
                successMessage: successMessage,
                errorMessage: errorMessage,
              })
            );
          }
          return res;
        })
        .catch((err) => {
          console.log("axios error:- ", err);
          dispatch(
            setStatus({
              successMessage: "",
              errorMessage: "File Failed to delete!!",
            })
          );
        });
    }
  };
};

export const addAccountsAsync = ({
  email,
  userName,
  estimated_value,
  file,
  fileName,
}) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      email: email,
      userName: userName,
      estimated_value: estimated_value,
      // cryptopia_docs: file[0],
    };

    let response = await API.post("/AddAccount", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        if (status) {
          dispatch(getAccountsAsync());
          dispatch(
            setStatus({
              successMessage: successMessage,
              errorMessage: errorMessage,
            })
          );
        }
        return res;
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Couldn't add account,Please refresh and try again",
          })
        );
      });
    if (response?.data?.accounts?.insertId && file?.length) {
      let cryptopia_info_id = response?.data?.accounts?.insertId;
      await Promise.all(file.map((file, index) => new Promise((response, reject) => {
        let data = new FormData();
        data.append("cryptopia_info_id", cryptopia_info_id);
        data.append("file", file);
        data.append("fileName", fileName[index]);
        data.append("token", storedToken);
        dispatch(setLoading());
        API.post(
          "/AddAccountFile",
          data,
          {
            "Content-Type": "multipart/form-data",
            headers: { "token": storedToken }
          }
        ).then((res) => {
          response(res)
        }).catch(err => reject())
      })))


      dispatch(getAccountsAsync());
      dispatch(
        setStatus({
          successMessage: "Upload Done",
          errorMessage: "",
        })
      );


    }
  };
};

export const deleteAccountsAsync = (id) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      accountId: id,
    };

    API.post("/RemoveAccount", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        if (status) {
          dispatch(getAccountsAsync());
          dispatch(
            setStatus({
              successMessage: successMessage,
              errorMessage: errorMessage,
            })
          );
        }
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage:
              "Couldn't remove account,Please refresh and try again",
          })
        );
      });
  };
};

const setSecurityPlan = (data) => {
  return {
    type: actionTypes.SET_SECURITYPLAN,
    payload: data,
  };
};

export const getSecurityPlanAsync = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
    };

    API.post("/GetSecurityPlan", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, securityplan } = res.data;
        dispatch(
          setSecurityPlan({
            securityplan: securityplan,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage:
              "Couldn't fetch security Plan,Please refresh and try again",
          })
        );
      });
  };
};

export const setSecurityPlanPayment = ({ cost_paid }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      cost: cost_paid,
    };

    API.post("/SetSecurityPayment", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
        dispatch(getSecurityPlanAsync());
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage:
              "Couldn't update security Plan,Please refresh and try again",
          })
        );
      });
  };
};

export const uploadSecurityFile = ({ file }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token") || getState().authReducer.token;
    let data = new FormData();
    data.append("file", file);
    data.append("token", storedToken);
    dispatch(setLoading());
    API.post("/UploadSecurityFile", data, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            error: !status,
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
        setTimeout(() => {
          dispatch(getUserSecurityFilesAsync());
        }, 3000);
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            status: false,
            successMessage: "",
            errorMessage: "Couldn't upload file,Please refresh and try again",
          })
        );
      });
  };
};

const setUserSecurityFiles = (data) => {
  return {
    type: actionTypes.UPDATE_SECURITY_FILES,
    payload: data,
  };
};

export const getUserSecurityFilesAsync = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
    };

    API.post("/GetSecurityFiles", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, files } = res.data;
        dispatch(
          setUserSecurityFiles({
            files: files,
          })
        );
        dispatch(
          setStatus({
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Couldn't fetch files,Please refresh and try again",
          })
        );
      });
  };
};

export const resendAgreementAsync = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.

    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
    };

    API.post("/ResendAgreements", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            successMessage: successMessage,
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage:
              "Couldn't resend agreements,Please refresh and try again",
          })
        );
      });
  };
};

export const finalSettlementCheck = (email, transactionId, callback) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token") || getState().authReducer.token;
    let reqBody = {
      email: email,
      transactionId: transactionId,
      token: storedToken,
    };
    dispatch(setLoading());
    API.post("/checkUserStatus", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        let stepsData = ["is_registered", "is_kyc_done", "is_payment_done"];
        let step = 0;
        stepsData.map((keys, index) => {
          if (res.data?.data[keys]) {
            step = index + 1;
          }
        });
        if (callback) {
          if (transactionId && res.data?.data && !res.data?.data[stepsData[2]])
            callback(false);
          else {
            callback(true);
          }
        }
        dispatch({
          type: actionTypes.UPDATE_SETTLEMENT_STATUS,
          payload: {
            response: {
              checking: false,
              step: step,
              status: {},
              settlementData: res?.data?.data,
            },
          },
        });
        dispatch(
          setStatus({
            successMessage: res.successMessage,
            errorMessage: res.errorMessage,
          })
        );
      })
      .catch((err) => {
        if (callback)
          callback(false);
        dispatch(
          setStatus({
            errorMessage: err.errorMessage,
          })
        );
        console.log(err);
      });
  };
};
