import React ,{ useState ,useEffect}  from 'react';
import { useParams } from "react-router";
import { connect } from "react-redux";

import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,Paper,Box,Collapse} from '@material-ui/core';
import { green,red } from '@material-ui/core/colors';
import { Alert } from '@material-ui/lab';
import {Error,CheckCircle} from '@material-ui/icons';

import Spinner from "../layout/Spinner/Spinner";
import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding:theme.spacing(3)
      },
    icon:{
        fontSize: 150
    }
  }));


const VerifyEmail = props => {

    const [token, setToken] = useState(useParams().token || null);

    const {OnCleaUp,onVerifyEmail,loading,successMessage,history} = props;

    useEffect(
        () => {
            if(token){
                onVerifyEmail(token);
            }
          return ()=>{
            OnCleaUp();
          }
        },
        [OnCleaUp,onVerifyEmail,token]
    );

    useEffect(
        () => {
          if(!loading && successMessage) {
            setTimeout(()=>{history.push("/LogIn")}, 3000);
          }
        },
        [successMessage,loading,history]
    );

    const classes = useStyles();
    return (
        <Container component="main" maxWidth="md">
            {loading ? 
                <Spinner/>
            : (
            <Paper className={classes.paper}>              
                <Collapse in={props.errorMessage.length > 0}>
                    <Error style={{ color: red[500] }} className={classes.icon}/>
                    <Alert severity="error">
                        {props.errorMessage}
                    </Alert>
                </Collapse>
                <Collapse in={props.successMessage.length > 0}>
                    <CheckCircle style={{ color: green[500] }} className={classes.icon}/>
                    <Alert severity="success">
                        {props.successMessage}
                    </Alert>
                </Collapse>
            </Paper>
            )}
            <Box mt={5}> 
                <Copyright />
            </Box>
        </Container>
    
  );
};
const mapStateToProps = ({ authReducer }) => {
    return {
        loading: authReducer.loading,
        isLoggedIn :authReducer.isLoggedIn,
        errorMessage: authReducer.errorMessage,
        successMessage: authReducer.successMessage
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onVerifyEmail: (token) => dispatch(actionCreator.VarifyEmailAsync(token)),
        OnCleaUp: () => dispatch(actionCreator.ResetErrors()),
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);