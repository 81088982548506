import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Collapse,
  Paper,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import Logo from "../layout/Logo";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  topLink: {
    marginBottom: theme.spacing(0),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const LogIn = (props) => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { isLoggedIn, loading, history, OnCleaUp, onIsLogInAsync } = props;

  useEffect(() => {
    return () => {
      OnCleaUp();
    };
  }, [OnCleaUp]);

  const onInputValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let userCopy = { ...user };
    userCopy[name] = value;
    setUser(userCopy);
  };

  const loginHandler = (e) => {
    e.preventDefault();
    props.onLogInAsync(user);
  };

  const classes = useStyles();

  useEffect(() => {
    let email = localStorage.getItem("loginEmail");
    let password = "";
    localStorage.setItem("loginEmail", "");
    if (email) {
      setUser((data) => ({ ...data, email: email, password: password }));
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Grid container alignItems="flex-end" justify="center">
        <Logo />
      </Grid>
      <Paper className={classes.paper}>
        <Grid container alignItems="flex-end" justify="space-between">
          <Grid item>
            <Typography component="h1" variant="h5">
              Log in
            </Typography>
          </Grid>
          <Grid item>
            <Link component={RouterLink} to="/register" variant="body2">
              Register New Account
            </Link>
          </Grid>
        </Grid>
        <form className={classes.form} onSubmit={loginHandler} method="post">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={user.email}
                onChange={onInputValueChanged}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={user.password}
                onChange={onInputValueChanged}
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={props.error}>
                <Alert severity="error">{props.errorMessage}</Alert>
              </Collapse>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end" spacing={1}>
            <Grid item xs={12} sm={6}>
              <Link component={RouterLink} to="/forgetpassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                disabled={props.loading}
                // className={classes.submit}
              >
                Log In
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn: authReducer.isLoggedIn,
    error: authReducer.error,
    errorMessage: authReducer.errorMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onLogInAsync: (userData) => dispatch(actionCreator.LogInAsync(userData)),
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
