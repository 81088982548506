import React ,{ useState ,useEffect}  from 'react';
import { useParams } from "react-router";
import { connect } from "react-redux";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import {Avatar,Button,CssBaseline,TextField,FormControlLabel,
        Checkbox,Link,Grid,Box,Typography,Container,Collapse,Paper  } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import Logo from "../layout/Logo";
import MenuAppBar from "../layout/MenuAppBar";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        padding:theme.spacing(3)
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    hide : {
      display:'none'
    }
}));

const ResetPassword = (props) => {
  const [user, setUser] = useState({
    password:"",
    re_password:"",
    resetToken: useParams().token || null
  });
  const [passwordError, setPasswordError] = useState({
    passwordStatus:false,
    re_passwordStatus:false,
    passwordErrorMessage:"",
    rePasswordErrorMessage:""
  });
  const {OnCleaUp,successMessage,errorMessage,isLoggedIn} = props;

  useEffect(
    () => {
      return ()=>{
        OnCleaUp();
      }
    },
    [OnCleaUp]
  );


  const validatePassword = (field,value) => {
    let passwordErrorCopy = { ...passwordError };
    let passwordRegEX = /(?=.*\d).{8,}/;
    if(field === "password"){
      if(value.trim().length===0){
        passwordErrorCopy.passwordErrorMessage = "This field is required";
        passwordErrorCopy.passwordStatus = false;
      }else if ( !passwordRegEX.test(value.trim())){
        passwordErrorCopy.passwordErrorMessage = "Password must contain at least 8 characters, including a number";
        passwordErrorCopy.passwordStatus = false;
      }else{
          passwordErrorCopy.passwordErrorMessage = "";
          passwordErrorCopy.passwordStatus = true;
      }
    }else{
      if(value !== user.password){
        passwordErrorCopy.rePasswordErrorMessage = "Passwords don't match";
        passwordErrorCopy.re_passwordStatus = false;
      }else{
        passwordErrorCopy.rePasswordErrorMessage = "";
        passwordErrorCopy.re_passwordStatus = true;
      }
    }
    setPasswordError(passwordErrorCopy);
  }

  const onInputValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let userCopy = { ...user };
    userCopy[name] = value;
    setUser(userCopy);
    validatePassword(name,value);
  }

  const resetPasswordHandler = e => {
    e.preventDefault();
    if(passwordError.passwordStatus && passwordError.re_passwordStatus){
      props.resetPasswordAsync({
        password:user.password,
        token:user.resetToken
      });
    }
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      {isLoggedIn ? <MenuAppBar/> : null}
      <Container component="main" maxWidth="xs">
      <Paper className={classes.paper}>
        <Grid container alignItems="flex-end" justify="center" className={props.isLoggedIn ? classes.hide : null}>
          <Logo />
        </Grid>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} onSubmit={resetPasswordHandler} method="post">
          <TextField
            error = {passwordError.passwordErrorMessage.length > 0}
            helperText={passwordError.passwordErrorMessage}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={user.password}
            onChange={onInputValueChanged}
          />
          <TextField
            error = {passwordError.rePasswordErrorMessage.length > 0}
            helperText={passwordError.rePasswordErrorMessage}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="re_password"
            label="confirm Password"
            type="password"
            id="re_password"
            autoComplete="current-password"
            value={user.re_password}
            onChange={onInputValueChanged}
          />
          <Collapse in={errorMessage.length > 0}>
            <Alert severity="error">
              {props.errorMessage}
            </Alert>
          </Collapse>
          <Collapse in={successMessage.length > 0}>
            <Alert severity="success">
              {props.successMessage}
            </Alert>
          </Collapse>
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="submit"
              //fullWidth
              variant="contained"
              color="primary"
              disabled={props.loading}
              className={classes.submit}
            >
              Reset
            </Button>
          </Box>
        </form>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </React.Fragment>
  );
}

const mapStateToProps = ({ authReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn :authReducer.isLoggedIn,
    error: authReducer.error,
    successMessage:authReducer.successMessage,
    errorMessage: authReducer.errorMessage
  };
};
const mapDispatchToProps = dispatch => {
  return {
    resetPasswordAsync: data => dispatch(actionCreator.ResetPasswordAsync(data)),
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)