export const base64toBlob = (data, type = 'application/pdf') => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    try {
        data = data.replaceAll('data:application/pdf;base64,', '');
        const base64WithoutPrefix = data;

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: type });
    }
    catch (err) {
        console.log("Err: ", err);
        return data;
    }
};