import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import './index.css';
import store from "./store/store";
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (process.env.REACT_APP_ENV === "prod" || process.env.REACT_APP_ENV === "dev") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN_KEY, //paste copied DSN value here
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0, //lower the value in production
  })
}

const app = (
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
