import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../asset/utility";

const initialState = {
  profile: null,
  isVerified: false,
  userPlan: null,
  selectedPlan: null,
  plans: null,
  files: null,
  securityFiles: null,
  accounts: null,
  securityPlan: null,
  cryptopiaFiles: [],
};

//# HERE IS THE REDUCER OR CASE'S
const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.UPDATE_PROFILE:
      return updateProfile(state, payload);
    case actionTypes.GET_PLANS:
      return getPlans(state, payload);
    case actionTypes.UPDATE_PLAN:
      return updatePlan(state, payload);
    case actionTypes.UPDATE_VERIFIED:
      return setIsVerified(state, payload);
    case actionTypes.UPDATE_FILES:
      return updateFiles(state, payload);
    case actionTypes.SET_SELECTED_PLAN:
      return setSelectedPlan(state, payload);
    case actionTypes.SET_ACCOUNTS:
      return setAccounts(state, payload);
    case actionTypes.SET_SECURITYPLAN:
      return setSecurityPlan(state, payload);
    case actionTypes.UPDATE_SECURITY_FILES:
      return updateSecurityFiles(state, payload);
    case actionTypes.CRYPTOPIA_FILES:
      return updateUserFiles(state, payload);
    default:
      return state;
  }
};

// /*--- CASES ARE HERE DEFINED */
// // UTILITY FUNCTION CALCULATE UTILIZATION

const updateUserFiles = (state, data) => {
  const { files } = data;
  return updateObject(state, {
    cryptopiaFiles: files,
  });
};

const updateProfile = (state, data) => {
  const { user } = data;
  return updateObject(state, {
    profile: user,
  });
};

const updatePlan = (state, data) => {
  const { userPlan } = data;
  return updateObject(state, {
    userPlan: userPlan,
  });
};

const getPlans = (state, data) => {
  const { plan } = data;
  return updateObject(state, {
    plans: plan,
  });
};
const setIsVerified = (state, data) => {
  const { isVerified } = data;
  return updateObject(state, {
    isVerified: isVerified,
  });
};
const updateFiles = (state, data) => {
  const { files } = data;
  return updateObject(state, {
    files: files,
  });
};
const setSelectedPlan = (state, data) => {
  const { plan } = data;
  return updateObject(state, {
    selectedPlan: plan,
  });
};

const setAccounts = (state, data) => {
  const { accounts } = data;
  return updateObject(state, {
    accounts: accounts,
  });
};

const setSecurityPlan = (state, data) => {
  const { securityplan } = data;
  return updateObject(state, {
    securityPlan: securityplan,
  });
};

const updateSecurityFiles = (state, data) => {
  const { files } = data;
  return updateObject(state, {
    securityFiles: files,
  });
};

export default userReducer;
