import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import * as actionCreator from "../../../store/action/index";
import { Button, Grid, TextField, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
  },
}));

const SendMail = (props) => {
  const { sendMailToAllUsers } = props;
  const initialState = {
    subject: "",
    body: "",
    message: "",
    sender: "",
    self: "",
  };
  const [formdata, setFormdata] = React.useState(initialState);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  const classes = useStyles();

  const onChangeHandler = (e) => {
    e.persist();
    setFormdata((currentData) => {
      let newChange = {};
      newChange[e.target.name] = e.target.value;
      return { ...currentData, ...newChange };
    });
    setError("");
    setSuccess("")
  };

  const sendMailHandler = (e) => {
    e.preventDefault();
    let htmlData = "";
    if (formdata.body.split("\n")?.length)
      formdata.body.split("\n").map((bodyData) => {
        htmlData += `<text>${bodyData}</text><br />`;
      });
    sendMailToAllUsers({ ...formdata, body: htmlData }).then((res) => {
      if (res?.status) {
        setFormdata(initialState);
        setSuccess("Mail sent!")
      } else {
        setError(
          res?.response?.data?.errorMessage?.message ||
            "Error while sending mail, Please check data."
        );
      }
    });
  };
 
  return (
    <React.Fragment>
      <form onSubmit={sendMailHandler} method="post">
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          spacing={1}
          className={classes.formControl}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Mail to all users</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              name="self"
              label="Send to(Sent to all by default)"
              id="self"
              fullWidth
              value={formdata.self}
              onChange={onChangeHandler}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              name="message"
              label="Mail Name"
              id="message"
              fullWidth
              value={formdata.message}
              onChange={onChangeHandler}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              name="subject"
              label="Subject"
              id="subject"
              fullWidth
              required
              value={formdata.subject}
              onChange={onChangeHandler}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              margin="normal"
              name="sender"
              label="Send from"
              id="sender"
              fullWidth
              value={formdata.sender}
              onChange={onChangeHandler}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              margin="normal"
              name="body"
              label="Mail content"
              id="body"
              multiline
              minRows={4}
              fullWidth
              required
              value={formdata.body}
              onChange={onChangeHandler}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="default"
              margin="dense"
              size="large"
            >
              Send Mail
            </Button>
            {error ? (
              <Grid item xs={12} style={{ textAlign: "center", color: "red" }}>
                <Typography variant="h6">{error}</Typography>
              </Grid>
            ) : null}
            {success ? (
              <Grid item xs={12} style={{ textAlign: "center", color: "green" }}>
                <Typography variant="h6">{success}</Typography>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendMailToAllUsers: (data) =>
      dispatch(actionCreator.sendMailToAllUsers(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendMail);
