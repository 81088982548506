import {
    Box,
    Grid,
    Typography,
    Button,
    TextField,
    makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import ImageSteps from "./ImageSteps";
import image1 from "../../asset/image/stepperImage/Step2.1.png"
import image2 from "../../asset/image/stepperImage/Step2.2.png"
import image3 from "../../asset/image/stepperImage/Step2.3.png"
import image4 from "../../asset/image/stepperImage/Step2.4.png"
import image5 from "../../asset/image/stepperImage/Step2.5.png"
import image6 from "../../asset/image/stepperImage/Step2.6.png"
import image7 from "../../asset/image/stepperImage/Step2.7.png"
import image8 from "../../asset/image/stepperImage/Step2.8.png"
import image9 from "../../asset/image/stepperImage/Step2.9.png"
import image10 from "../../asset/image/stepperImage/Step2.10.jpg"
import image11 from "../../asset/image/stepperImage/Step2.11.jpg"
import image12 from "../../asset/image/stepperImage/Step2.12.png"
import StepperImage from "./StepperImage";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
}));

const imageList = [image1, image2, image3, image4, image5, image6, image7, image8, image9]

const KycContent = ({ close, finalSettlementCheck, step, profile }) => {
    const classes = useStyles();

    return (
        <>
            <Box className="list-template">
                <ul>
                    <li>
                        <strong>Congratulations!</strong> You now have your very own Cogito account! Now <strong>“sign in”</strong>,and let’s set up and verify your <strong>KYC</strong> (Know Your Customer). Go to this link  <a href="https://app.principalityofcogito.com/login" target={"_blank"}>https://app.principalityofcogito.com/login</a> to sign in.
                    </li>
                    <li>Once you’ve signed in, go to <strong>profile</strong> (it’s the person-shaped icon on the sidebar on the left).</li>
                    <Grid container spacing={1}>
                        <Grid item sm={3} xs={0}></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image1} /></Grid>
                        <Grid item sm={3} xs={0}></Grid>
                    </Grid>
                    <li>Go to <strong>“Your KYC Status”</strong> and click <strong>“View Now”</strong>. Then <strong>“check”</strong> the box that says you agree to the processing of your data and then click <strong>“Next”</strong>.</li>
                    <Grid container spacing={1}>
                        <Grid item sm={4} xs={12}><StepperImage image={image2} /></Grid>
                        <Grid item sm={4} xs={12}><StepperImage image={image3} /></Grid>
                        <Grid item sm={4} xs={12}><StepperImage image={image4} /></Grid>
                    </Grid>
                    <li>Next, you will be asked to share your identity document (Passport, Driver’s license, ID card, etc.). Just upload a picture of your preferred document and then click <strong>“Next”</strong>.</li>
                    <Grid container spacing={1}>
                        <Grid item sm={6} xs={12}><StepperImage image={image5} /></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image6} /></Grid>
                    </Grid>
                    <li>Click <strong>“View Now”</strong> again in the <strong>“Your KVC Status”</strong> section.</li>
                    <li><strong>Note:</strong> You have to have a camera in this section because we’ll be taking a <strong>“selfie”</strong> or photo of you. This is to add another layer of security to your account.</li>
                    <Grid container spacing={1}>
                        <Grid item sm={3} xs={0}></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image7} /></Grid>
                        <Grid item sm={3} xs={0}></Grid>
                    </Grid>
                    <li>Once done we will then process your <strong>“application data”</strong> and <strong>“identity documents”</strong> for authentification.</li>
                    <Grid container spacing={1}>
                        <Grid item sm={3} xs={0}></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image8} /></Grid>
                        <Grid item sm={3} xs={0}></Grid>
                    </Grid>
                    <li>This may take an hour to a few days for <strong>“Your KYC Status”</strong> to be verified.</li>
                    <Grid container spacing={1}>
                        <Grid item sm={3} xs={0}></Grid>
                        <Grid item sm={6} xs={12}><StepperImage image={image9} /></Grid>
                        <Grid item sm={3} xs={0}></Grid>
                    </Grid>
                    <li>Once verified it would turn from <strong>“incomplete”</strong> to <strong>“complete”</strong>.</li>
                    <li>You will also receive an email giving you your <strong>“pin number”</strong>. This is proof that your account has been verified. You will be needing this for every transaction that you will be needing. This is also another layer that we have implemented to protect our users in processing their transactions.</li>
                    <Grid container spacing={1}>
                        <Grid item sm={4} xs={12}><StepperImage image={image10} /></Grid>
                        <Grid item sm={4} xs={12}><StepperImage image={image11} /></Grid>
                        <Grid item sm={4} xs={12}><StepperImage image={image12} /></Grid>
                    </Grid>
                    <li><strong>Note: Do not share your pin with anyone.</strong></li>
                </ul>
                {/* <ImageSteps imageList={imageList} /> */}
            </Box>
        </>
    );
};
const mapStateToProps = ({ }) => {
    return {

    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(KycContent);
