import React, {useState ,useEffect,forwardRef} from 'react';
import { connect } from "react-redux";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {AddBox,ArrowDownward,Check,ChevronLeft,ChevronRight,Clear,
    DeleteOutline,Edit,FilterList,FirstPage,LastPage,Remove,SaveAlt,Search,ViewColumn} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import {Grid,Typography,Button,TextField,Collapse } from '@material-ui/core';
import MaterialTable from "material-table";

import * as actionCreator from "../../../store/action/index";


const useStyles = makeStyles((theme) =>({
    formControl: {
        marginTop: theme.spacing(2)
    },
}));

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const AdminTable = (props) => {
  
  const {loading,errorMessage,successMessage,adminDetails,OnCleaUp,courtData,fetchCourtDataList,
    fetchAllAdmin,admins,resetAdminPassword,addNewAdmin,removeAdmin,editAdmin} = props;

  const [onlyAdmin, setOnlyAdmin] = useState(null);
  const [password, setPassword] = useState({
    password:"",
    rePassword:""
  });

    useEffect(
    () => {
      if(adminDetails.enabled && !admins){
        fetchAllAdmin();
      }else if(!adminDetails.enabled){
        setOnlyAdmin(adminDetails);
      }
      if(!courtData){
        fetchCourtDataList()
      }
      return ()=>{
        OnCleaUp();
      }
    },
    [OnCleaUp,adminDetails,admins,fetchAllAdmin,courtData,fetchCourtDataList]
  );

  const onValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value; 
    if(name == "password" || name == "rePassword"){
      let passwordCopy = { ...password };
      passwordCopy[name] = value;
      setPassword(passwordCopy);
    }
  }

  const resetPasswordHandler = (e) => {
    e.preventDefault();
    if(onlyAdmin && password.password === password.rePassword){
      resetAdminPassword({
        email:onlyAdmin.email,
        password:password.password
      })
    }
    else{
      alert("Please select an admin or Passwords don't match!!");
    }
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container alignItems="center" spacing={1} className={classes.formControl}>
        <Grid item xs={12}>
        {!admins ? null:
            <MaterialTable
                icons={tableIcons}
                title="Admins"
                columns={[
                    { title: 'Email', field: 'email' },
                    { title: 'Admin Status', field: 'enabled' ,lookup: { 0: 'Admin', 1: 'Super Admin' } },
                    { title: 'Admin Access', field: 'access' ,lookup: { 0: 'No', 1: 'Yes' } },
                    { title: 'Last Login Date', field: 'date' , editable: 'never'},
                    { title: 'Last Login Time', field: 'time' , editable: 'never'}
                ]}
                data={admins}
                editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                          addNewAdmin(newData);
                          resolve();
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        editAdmin(newData);
                        resolve();
                      }),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        removeAdmin(oldData)
                        resolve();
                      }),
                  }}
                options={{
                    pageSize:20,
                    pageSizeOptions:[10,20,50],
                    selection: true,
                    doubleHorizontalScroll:true,
                    emptyRowsWhenPaging:false,
                    padding:'dense',
                    headerStyle: {
                        backgroundColor: '#3F51B5',
                        color: '#FFF'
                      },
                    rowStyle: {
                        backgroundColor: '#EEE',
                      }
                }}
                onSelectionChange={(rows) => {
                    {rows.length === 1 ? setOnlyAdmin(rows[0]) : setOnlyAdmin(null)}
                }}
            />
          }
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={1} className={classes.formControl}>
          <Grid item xs={12}>
          {!courtData ? null:
            <MaterialTable
                icons={tableIcons}
                title="Sent To Court List"
                columns={[
                    { title: 'User', field: 'email' },
                    { title: 'Time', field: 'time'},
                    { title: 'Date', field: 'date'},
                    { title: 'Admin', field: 'admin' },
                ]}
                data={courtData ? courtData: []}
                options={{
                    pageSize:20,
                    pageSizeOptions:[10,20,50],
                    doubleHorizontalScroll:true,
                    emptyRowsWhenPaging:false,
                    padding:'dense',
                    headerStyle: {
                        backgroundColor: '#3F51B5',
                        color: '#FFF'
                      },
                    rowStyle: {
                        backgroundColor: '#EEE',
                      }
                }}
            />
          }
          </Grid>
        </Grid>
        <form onSubmit={resetPasswordHandler} method="post">
            <Grid container direction="row" alignItems="center" spacing={1} className={classes.formControl}> 
                <Grid item xs={12}>
                    <Collapse in={successMessage.length > 0}>
                        <Alert severity="success">
                            {successMessage}
                        </Alert>
                    </Collapse>
                </Grid>
                <Grid item xs={12}>
                    <Collapse in={errorMessage.length > 0}>
                        <Alert severity="error">
                            {errorMessage}
                        </Alert>
                    </Collapse>
                </Grid> 
                <Grid item xs={12}>
                  <Typography variant="h6" >
                    Reset Password
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component="h1" variant="subtitle2" align="justify" color="textSecondary">
                    Password must contain at least 8 characters, including a number
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    variant="outlined"
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="password"
                    fullWidth
                    required
                    margin="dense"
                    value={password.password}
                    onChange={onValueChanged}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    variant="outlined"
                    name="rePassword"
                    label="confirm Password"
                    type="password"
                    id="rePassword"
                    autoComplete="password"
                    fullWidth
                    required
                    margin="dense"
                    value={password.rePassword}
                    onChange={onValueChanged}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="default"
                        margin="dense"
                        size="large"
                        disabled={loading}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    </React.Fragment>
  );
}

const mapStateToProps = ({ authReducer,adminReducer }) => {
    return {
        loading: authReducer.loading,
        errorMessage: authReducer.errorMessage,
        successMessage: authReducer.successMessage,

        adminDetails:adminReducer.adminDetails,
        courtData:adminReducer.courtData,
        admins:adminReducer.admins
    };
};
const mapDispatchToProps = dispatch => {
    return {
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),

    fetchAllAdmin: () => dispatch(actionCreator.fetchAllAdmin()),
    resetAdminPassword: (data) => dispatch(actionCreator.resetAdminPassword(data)),
    addNewAdmin : (data) => dispatch(actionCreator.addNewAdmin(data)),
    removeAdmin : (id) => dispatch(actionCreator.removeAdmin(id)),
    editAdmin : (data) => dispatch(actionCreator.editAdmin(data)),
    fetchCourtDataList : (data) => dispatch(actionCreator.fetchCourtDataList(data)),
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(AdminTable)
