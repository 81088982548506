import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from './../../crypto-logo.jpg';


const useStyles = makeStyles((theme) => ({
    logo: {
        margin: theme.spacing(1,0),
        maxWidth:"70%"
    },
  }));

const Logo = () => {
    const classes = useStyles();

  return <img src={logo} className={classes.logo} alt="Logo" />;
}

export default Logo;