import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Paper from "@material-ui/core/Paper";
import DetailPanel from "./detailPanel";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DetailDialog = (props) => {
  const { status, onDialogClose } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={status}
      onClose={() => onDialogClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { maxWidth: 1200, width: "90vw" } }}
    >
      <DialogTitle id="alert-dialog-title">Cryptopia Accounts</DialogTitle>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={() => onDialogClose(false)}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box>
          <DetailPanel
            email={status && status[0] && status[0].email}
            accounts={status}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DetailDialog;
