import * as actionTypes from "./actionTypes";
import axios from "axios";

import qs from "qs";
import { API,setWithExpiry,getWithExpiry } from "../../asset/api";

const setLoading = () => {
  return {
    type: actionTypes.SET_LOADING
  };
};

const setStatus = data => {
  return {
    type: actionTypes.SET_STATUS,
    payload: data
  };
};

const register = data => {
  return {
    type: actionTypes.REGISTER,
    payload: data
  };
};

export const RegisterAsync = ({ firstName, lastName, email, password }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading());
    const reqBody ={
      email:email,
      password:password,
      firstName:firstName,
      lastName:lastName
    };
    API.post("/Register", qs.stringify(reqBody), {
        withCredentials: false
      })
      .then(res => {
        const { status, successMessage , errorMessage } = res.data;
        dispatch(
          register({
            status: status,
            successMessage:successMessage,
            errorMessage: errorMessage
          })
        );
      })
      .catch(err => {
        console.log("axios error:- ",err);
        dispatch(
          register({
            status: false,
            successMessage:"",
            errorMessage: "Please try again by refresh..!"
          })
        );
      });
  };
};

export const VarifyEmailAsync =(token)=>{
  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading());
    const reqBody ={
      token:token
    };
    API.post("/Verify", qs.stringify(reqBody), {
        withCredentials: false
      })
      .then(res => {
        const { status, successMessage , errorMessage } = res.data;
        dispatch(
          setStatus({
            successMessage:successMessage,
            errorMessage: errorMessage
          })
        );
      })
      .catch(err => {
        console.log("axios error:- ",err);
        dispatch(
            setStatus({
            successMessage:"",
            errorMessage: "Please try again by refresh..!"
          })
        );
      });
  };
}


const logIn = data => {
  return {
    type: actionTypes.LOGIN,
    payload: data
  };
};

export const LogInAsync = ({ email, password }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading());
    const reqBody ={
      email:email,
      password:password
    };
    API.post("/Login", qs.stringify(reqBody), {
        withCredentials: false
      })
      .then(res => {
        const { status, successMessage , errorMessage, token } = res.data;
        if(status){
          setWithExpiry("token", token, 1);
        };
        
        dispatch(
          logIn({
            status: status,
            successMessage:successMessage,
            errorMessage:errorMessage,
            token:token
          })
        );
      })
      .catch(err => {
        console.log("axios error:- ",err);
        dispatch(
          logIn({
            status: false,
            successMessage:"",
            errorMessage: "Please try again by refresh..!",
            token:null
          })
        );
      });
  };
};

export const ForgetPasswordAsync = (email) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading());
    const reqBody ={
      email:email
    };
    API.post("/ForgotPassword", qs.stringify(reqBody), {
        withCredentials: false
      })
      .then(res => {
        const { status, successMessage , errorMessage } = res.data;
        dispatch(
          setStatus({
            error: !status,
            successMessage:successMessage,
            errorMessage: errorMessage
          })
        );
      })
      .catch(err => {
        console.log("axios error:- ",err);
        dispatch(
          setStatus({
            error: true,
            successMessage:"",
            errorMessage: "Please try again by refresh..!"
          })
        );
      });
  };
};

export const ResetPasswordAsync = ({ token, password }) => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    dispatch(setLoading());
    const reqBody ={
      token:token,
      password:password
    };
    API.post("/ResetPassword", qs.stringify(reqBody), {
        withCredentials: false
      })
      .then(res => {
        const { status, successMessage , errorMessage } = res.data;
        dispatch(
          setStatus({
            error: !status,
            successMessage:successMessage,
            errorMessage: errorMessage
          })
        );
      })
      .catch(err => {
        console.log("axios error:- ",err);
        dispatch(
          setStatus({
            error: true,
            successMessage:"",
            errorMessage: "Please try again by refresh..!"
          })
        );
      });
  };
};

export const ResetErrors = () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S
  return (dispatch, getState) => {
    dispatch(setStatus({
      error: null,
      successMessage:"",
      errorMessage: ""
    }))
  };
};

const isLogIn = data => {
  return {
    type: actionTypes.IS_LOG_IN,
    payload: data
  };
};

export const IsLogInAsync =  () => {
  //# HERE WE CREATE DYNMAIC ACTION DISPATCHER FOR ALL CASE'S

  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry('token');
    const reqBody ={
      token:storedToken
    };
    API.post("/IsLoggedIn", qs.stringify(reqBody), {
        withCredentials: false
      })
      .then(res => {
        const { status, successMessage , errorMessage } = res.data;
        dispatch(
          isLogIn({
              status: status
            })
        );
      })
      .catch(err => {
        console.log("axios error:- ",err);
        dispatch(
          isLogIn({
              status: false
            })
        );
      });
  };
};

const logout = ()=> {
  return {
    type: actionTypes.LOGOUT
  };
}
export const OnLogout = ()=> {
  return (dispatch, getState) => {
    dispatch(setLoading());
    localStorage.removeItem("token");
    dispatch(logout());
  }
}
