import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { Done, ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import {
  Collapse,
  Grid,
  Box,
  Typography,
  Container,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import MenuAppBar from "../layout/MenuAppBar";
import PaymentDialog from "./microcomponents/PaymentDialog";
import SecurityPlan from "./microcomponents/SecurityPlan";

import PlanList from "./microcomponents/PlanList";
import Spinner from "../layout/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  itemHolder: {
    marginTop: theme.spacing(3),
  },
  rightSide: {
    marginLeft: "auto",
    //backgroundColor: theme.palette.primary.light
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Plans = (props) => {
  const {
    OnCleaUp,
    history,
    profile,
    securityPlan,
    loading,
    onGetProfile,
    userPlan,
    onGetPlans,
    plans,
    onGetSecurityPlan,
    onSetSelectedPlan,
    onSelectPlanAsync,
    finalSettlementCheck,
  } = props;

  const [DialogOpen, setDialogOpen] = useState(false);

  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    if (!plans) {
      onGetPlans();
    }
  },[plans])

  useEffect(() => {
    if (!userPlan) {
      onGetProfile();
    }
  },[userPlan])

  useEffect(() => {
    if (!securityPlan) {
      onGetSecurityPlan();
    }
  },[securityPlan])

  useEffect(() => {
    return () => {
      OnCleaUp();
    };
  }, [
    onGetProfile,
    onGetPlans,
    plans,
    userPlan,
    OnCleaUp,
    onGetSecurityPlan,
    securityPlan,
  ]);

  useEffect(() => {
    if (profile) finalSettlementCheck(profile.email);
  }, [profile]);

  const onDialogCloseHandler = (status) => {
    setSelectedPlan(null);
    setDialogOpen(status);
  };

  const selectPlanHandler = (plan) => {
    // if (!userPlan || userPlan.isActive == 0) {
    if (true) {
      setSelectedPlan(plan);
      setDialogOpen(true);
    }
  };

  const onConfirmSelectedPlan = async () => {
    // if (!userPlan || userPlan.isActive == 0) {
    if (true) {
      await onSetSelectedPlan(selectedPlan);
      setDialogOpen(false);
      // if (selectedPlan.costPaid === 0) {
      onSelectPlanAsync({
        planId: selectedPlan.planId,
        costPaid: selectedPlan.costPaid,
      });
      // } else {
      // history.push("/Payments");
      // }
    }
  };

  const classes = useStyles();
  return (
    <React.Fragment>
      <MenuAppBar />
      <Container component="main" maxWidth="md">
        {userPlan && userPlan.isActive == 0 ? (
          <Box>
            <Alert severity="warning" className={classes.rightSide}>
              You are currently on old plan, please select a new plan
            </Alert>
          </Box>
        ) : null}
        <Paper className={classes.paper}>
          {false && (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography variant="h5" align="left" display="inline">
                  Register for claim for breach of privacy and for supply of
                  personal data
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SecurityPlan />
              </AccordionDetails>
            </Accordion>
          )}

          {/* <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            > */}
              <Typography variant="h5" align="left" display="inline">
                Select a settlement plan
              </Typography>
              {userPlan ? (
                <Alert severity="info" className={classes.rightSide}>
                  You have selected {userPlan.shortDescription}
                </Alert>
              ) : (
                <Alert severity="warning" className={classes.rightSide}>
                  {`Select one of the ${plans?.length} Settlement plans currently available`}
                </Alert>
              )}
            {/* </AccordionSummary>
            <AccordionDetails> */}
              {loading ? null : (
                <PlanList
                  plans={plans}
                  userPlan={userPlan}
                  // available={!userPlan || userPlan.isActive == 0 ? false : true}
                  available={false}
                  onSelect={selectPlanHandler}
                />
              )}
            {/* </AccordionDetails>
          </Accordion> */}

          {loading ? (
            <Spinner />
          ) : selectedPlan && DialogOpen ? (
            <PaymentDialog
              status={DialogOpen}
              onDialogClose={onDialogCloseHandler}
              OnConfirmPlan={onConfirmSelectedPlan}
              plan={selectedPlan}
            />
          ) : null}
        </Paper>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authReducer, userReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn: authReducer.isLoggedIn,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,
    profile: userReducer.profile,
    userPlan: userReducer.userPlan,
    plans: userReducer.plans,
    securityPlan: userReducer.securityPlan,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),
    finalSettlementCheck: (email) =>
      dispatch(actionCreator.finalSettlementCheck(email)),
    onGetProfile: () => dispatch(actionCreator.GetProfileAsync()),
    onGetPlans: () => dispatch(actionCreator.GetPlansAsync()),
    onSetSelectedPlan: (plan) => dispatch(actionCreator.SetSelectedPlan(plan)),
    onGetSecurityPlan: () => dispatch(actionCreator.getSecurityPlanAsync()),
    onSelectPlanAsync: (plan) => dispatch(actionCreator.SelectPlanAsync(plan)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
