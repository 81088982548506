import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../asset/utility";

const initialState = {
  status: null,
  step: null,
  checking: true,
};

const finalSettlementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.UPDATE_SETTLEMENT_STATUS:
      return updateSettlementStatus(state, payload);
    default:
      return state;
  }
};
const updateSettlementStatus = (state, data) => {
  let { response } = data;
  return updateObject(state, {
    ...response,
  });
};
export default finalSettlementReducer;
