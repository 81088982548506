import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  IconButton,
  Collapse,
  Container,
  Grid,
  Box,
  CssBaseline,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { ExpandLess, ExpandMore, Delete, Edit } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import MenuAppBar from "../layout/MenuAppBar";
import EditCryptopiaDialog from "./microcomponents/EditCryptopiaDialog";
import AddDocs from "./microcomponents/AddDocs";
import clsx from "clsx";
import { base64toBlob } from "../../utils/blob/base64ToBlob";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    minWidth: 300,
  },
  title: {
    marginRight: theme.spacing(1),
    display: "inline",
  },
  table: {
    minWidth: 300,
  },
}));

const AccountsInfo = (props) => {
  const {
    OnCleaUp,
    history,
    onIsLogInAsync,
    isLoggedIn,
    loading,
    errorMessage,
    successMessage,
    onGetAccountsAsync,
    accounts,
    onAddAccountsAsync,
    onDeleteAccountsAsync,
    onEditAccountsAsync,
  } = props;

  const [newAccount, setNewAccount] = useState({
    email: "",
    userName: "",
    estimated_value: 0.0,
    file: [],
    fileName: [],
  });
  const [errorMessages, setErrorMessages] = useState({
    emailError: "",
    userNameError: "",
  });
  const [validData, setvalidData] = useState({
    validUserName: false,
    validEmail: false,
  });
  const [editItem, setEditItem] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (!accounts) {
      onGetAccountsAsync();
    }
    return () => {
      OnCleaUp();
    };
  }, [OnCleaUp, accounts, onGetAccountsAsync]);

  const toggleForm = (e) => {
    setShowAddForm(!showAddForm);
  };

  const validateField = (field, value) => {
    let errorMessagesCopy = { ...errorMessages };
    let validDataCopy = { ...validData };

    if (field === "userName") {
      if (value.trim().length === 0) {
        errorMessagesCopy.userNameError = "This field is required";
        validDataCopy.validUserName = false;
      } else {
        validDataCopy.validUserName = true;
        errorMessagesCopy.userNameError = "";
      }
    } else if (field === "email") {
      let emailRegEX =
        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if (value.trim().length === 0) {
        errorMessagesCopy.emailError = "This field is required";
        validDataCopy.validEmail = false;
      } else if (!emailRegEX.test(value.trim())) {
        errorMessagesCopy.emailError = "please enter a valid email address";
        validDataCopy.validEmail = false;
      } else {
        errorMessagesCopy.emailError = "";
        validDataCopy.validEmail = true;
      }
    }
    setErrorMessages(errorMessagesCopy);
    setvalidData(validDataCopy);
  };

  const onInputValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let userCopy = { ...newAccount };
    userCopy[name] = value;
    setNewAccount(userCopy);
    validateField(name, value);
  };
  console.log("Files: ", newAccount.file)
  const onDialogCloseHandler = (status) => {
    setShowDialog(status);
  };

  const addNewAccount = (e) => {
    e.preventDefault();
    if (validData.validEmail && validData.validUserName) {
      onAddAccountsAsync(newAccount);
    }
  };

  const editItemHandle = (item) => {
    setEditItem(item);
    setShowDialog(true);
  };

  const removeItem = (itemId) => {
    onDeleteAccountsAsync(itemId);
  };

  const confirmEdit = (item) => {
    onEditAccountsAsync(item);
    setShowDialog(false);
  };

  const classes = useStyles();
  const columnStyle = { overflowWrap: "anywhere" };
  return (
    <React.Fragment>
      <MenuAppBar />
      <Container component="main" maxWidth="md">
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={columnStyle}>
                      Cryptopia Email Address
                    </StyledTableCell>
                    <StyledTableCell align="center" style={columnStyle}>
                      Cryptopia User Name
                    </StyledTableCell>
                    <StyledTableCell align="center" style={columnStyle}>
                      Estimated USD Value
                    </StyledTableCell>
                    <StyledTableCell align="center" style={columnStyle}>
                      Edit
                    </StyledTableCell>
                    <StyledTableCell align="center" style={columnStyle}>
                      Remove
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts &&
                    accounts.map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={columnStyle}
                        >
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="center" style={columnStyle}>
                          {row.userName}
                        </StyledTableCell>
                        <StyledTableCell align="center" style={columnStyle}>
                          ${row.estimated_value}
                        </StyledTableCell>
                        <StyledTableCell align="center" style={columnStyle}>
                          <IconButton
                            color="default"
                            aria-label="Edit"
                            onClick={(event) => editItemHandle(row)}
                            component="span"
                          >
                            <Edit fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell align="right" style={columnStyle}>
                          <IconButton
                            color="default"
                            aria-label="Delete"
                            onClick={(event) => removeItem(row.id)}
                            component="span"
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <Collapse in={errorMessage.length > 0}>
            <Alert severity="error">{errorMessage}</Alert>
          </Collapse>
          <Collapse in={successMessage.length > 0}>
            <Alert severity="success">{successMessage}</Alert>
          </Collapse>
          {/* {newAccount.file.length ? <Grid item xs={12}>
            {newAccount.file.map(data => {
              console.log("Hey")
              return <iframe key={data} src={URL.createObjectURL(base64toBlob(data, 'application/pdf'))} />
            })}
          </Grid> : null} */}

          {accounts && accounts.length ? null : <Grid item xs={12}>
            <Typography
              variant="h6"
              className={clsx(classes.title, "hover")}
              onClick={toggleForm}
            >
              Add New Cryptopia Account
            </Typography>
            <IconButton
              color="default"
              aria-label="upload file"
              onClick={toggleForm}
              component="span"
            >
              {showAddForm ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <Collapse in={showAddForm}>
              <form onSubmit={addNewAccount} method="post">
                <Grid
                  container
                  justify="space-evenly"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={errorMessages.emailError.length > 0}
                      helperText={errorMessages.emailError}
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      type="email"
                      name="email"
                      autoComplete="email"
                      value={newAccount.email}
                      onChange={onInputValueChanged}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      error={errorMessages.userNameError.length > 0}
                      helperText={errorMessages.userNameError}
                      variant="outlined"
                      required
                      fullWidth
                      id="userName"
                      label="User Name"
                      name="userName"
                      autoComplete="userName"
                      value={newAccount.userName}
                      onChange={onInputValueChanged}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="estimated_value"
                      label="Estimated Value"
                      type="number"
                      name="estimated_value"
                      autoComplete="estimated_value"
                      value={newAccount.estimated_value}
                      onChange={onInputValueChanged}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AddDocs selectId={"add"} setBlob={setNewAccount} />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        size="large"
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={props.loading}
                      >
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Collapse>
          </Grid>}
        </Paper>
        <EditCryptopiaDialog
          status={showDialog}
          editItem={editItem}
          loading={loading}
          onDialogClose={onDialogCloseHandler}
          confirmEdit={confirmEdit}
        />
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authReducer, userReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn: authReducer.isLoggedIn,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,

    accounts: userReducer.accounts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),

    onGetAccountsAsync: () => dispatch(actionCreator.getAccountsAsync()),
    onDeleteAccountsAsync: (data) =>
      dispatch(actionCreator.deleteAccountsAsync(data)),
    onAddAccountsAsync: (data) =>
      dispatch(actionCreator.addAccountsAsync(data)),
    onEditAccountsAsync: (data) =>
      dispatch(actionCreator.editAccountsAsync(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsInfo);
