import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { PayPalButton } from "react-paypal-button-v2";

import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Paper, Box, Typography, Collapse } from '@material-ui/core';

import Spinner from "../layout/Spinner/Spinner";
import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import { PAYPAL_CLIENT_ID } from "../../asset/api";
import { parseToHtml } from '../../utils/parser/parseHtml';



const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
}));


const Payments = props => {
    const { OnCleaUp, isLoggedIn, loading, onIsLogInAsync, history, selectedPlan, onSelectPlanAsync, userPlan, successMessage } = props;

    useEffect(
        () => {
            if (!selectedPlan || (!loading && userPlan)) {
                history.push("/plans");
            }
            return () => {
                OnCleaUp();
            }
        },
        [loading, userPlan, selectedPlan, history]
    );

    const classes = useStyles();
    return (
        <Container component="main" maxWidth="md">
            {loading || !selectedPlan ?
                <Spinner />
                : (
                    <Paper className={classes.paper}>
                        <Typography variant="h6">
                            {selectedPlan.planName}
                        </Typography>
                        <Typography variant="body2" component="p">
                            {parseToHtml({ data: selectedPlan.planDescription })}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                            €{selectedPlan.costPaid}
                        </Typography>
                        <PayPalButton
                            amount={selectedPlan.costPaid}
                            currency="EUR"

                            onSuccess={(details, data) => {
                                alert("Transaction completed by " + details.payer.name.given_name);

                                // OPTIONAL: Call your server to save the transaction
                                onSelectPlanAsync({
                                    planId: selectedPlan.planId,
                                    costPaid: selectedPlan.costPaid
                                })
                            }}

                            options={{
                                clientId: PAYPAL_CLIENT_ID,
                                currency: "EUR"
                            }}

                            style={
                                {
                                    shape: 'rect',
                                    color: 'gold',
                                    layout: 'vertical',
                                    label: 'paypal'

                                }
                            }

                        />
                    </Paper>
                )}
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>

    );
};
const mapStateToProps = ({ authReducer, userReducer }) => {
    return {
        loading: authReducer.loading,
        isLoggedIn: authReducer.isLoggedIn,
        errorMessage: authReducer.errorMessage,
        successMessage: authReducer.successMessage,
        selectedPlan: userReducer.selectedPlan,
        userPlan: userReducer.userPlan,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        OnCleaUp: () => dispatch(actionCreator.ResetErrors()),
        onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),

        onSelectPlanAsync: (plan) => dispatch(actionCreator.SelectPlanAsync(plan)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payments);