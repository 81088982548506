import React, { useState, useEffect, forwardRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Blob from "blob";
import { connect } from "react-redux";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import {
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Grid,
  Collapse,
  InputLabel,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import { saveAs } from "file-saver";
import { Alert } from "@material-ui/lab";
import MaterialTable from "material-table";

import * as actionCreator from "../../../store/action/index";
import { toBase64 } from "../../../utils/buffer/toBase64";
import { bufferToStream } from "../../../utils/blob/bufferToStream";
import { base64toBlob } from "../../../utils/blob/base64ToBlob";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const RetriveDocs = (props) => {
  const {
    loading,
    errorMessage,
    successMessage,
    adminData,
    user,
    getDocuments,
    retriveDocuments,
    fileList,
    statusTypes,
    updateDocumentStatus,
  } = props;

  const [email, setEmail] = useState(adminData.email);
  const [selectedDocs, setSelectedDocs] = useState(null);
  const [files, setFiles] = useState(null);
  const [docStatus, setDocStatus] = useState(0);

  const onValueChanged = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "email") {
      setEmail(value);
    }
    if (name == "docStatus") {
      setDocStatus(value);
    }
  };

  const retriveDocumentHandler = (e) => {
    e.preventDefault();
    if (selectedDocs) {
      retriveDocuments({
        adminEmail: email,
        user: user,
        kycDocs: selectedDocs.kycDocs,
        securityDocs: selectedDocs.securityDocs,
        cryptopiaDoc: selectedDocs.cryptopiaDoc,
      });
    }
  };

  const fetchDocumentHandler = () => {
    getDocuments({
      userId: user.id,
    });
  };

  const onDocumentSelect = (data) => {
    // let tempKyc = [];
    // let tempSec = [];
    let cryptopiaDoc = [];
    if (data.length > 0) {
      data.forEach((item) => {
        // if(item.type != "security agreement"){
        //     tempKyc.push(item.id);
        // }else{
        //     tempSec.push(item.id);
        // }
        cryptopiaDoc.push(item.id);
      });
      setSelectedDocs({
        // kycDocs: tempKyc,
        // securityDocs: tempSec,
        cryptopiaDoc: cryptopiaDoc,
      });
    } else {
      setSelectedDocs(null);
    }
  };

  const documentStatusHandler = (e) => {
    e.preventDefault();
    updateDocumentStatus({
      kycDocs: selectedDocs.kycDocs,
      SecurityDocs: selectedDocs.securityDocs,
      docStatus: docStatus,
      userId: user.id,
    });
  };

  const previewDoc = async (row) => {
    console.log("Row: ", row)
    let base64Doc = toBase64(row?.file?.type === "Buffer" ? row?.file?.data : row?.file)

    // let blob = base64toBlob(base64Doc, 'application/pdf')

    // let blob = new Blob([row?.file?.data || []], { type: 'application/pdf' })
    // let url = URL.createObjectURL(base64toBlob(base64Doc, 'application/pdf'));
    // console.log("URL :", url)
     window.open(URL.createObjectURL(base64toBlob(`data:application/pdf;base64,${base64Doc}`, 'application/pdf')));
    // saveAs(
    //   url,
    //   row.name
    // );
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        item
        direction="row"
        alignItems="center"
        spacing={1}
        className={classes.formControl}
      >
        <Grid item xs={12}>
          <Typography variant="h6">Retrive Documents</Typography>
        </Grid>

        <Grid item xs={12}>
          <Button
            //fullWidth
            variant="contained"
            color="default"
            margin="dense"
            size="large"
            disabled={loading || !user}
            onClick={fetchDocumentHandler}
          >
            Fetch Documents
          </Button>
        </Grid>

        <Grid item xs={12}>
          {fileList && user ? (
            <MaterialTable
              icons={tableIcons}
              title="User Documents"
              columns={[
                {
                  title: "Name", field: "name", editable: "never", render: (row) => {
                    return (<Typography
                      onClick={() => {
                        previewDoc(row)
                      }}
                      className="hover">{row.name}</Typography>)
                  }
                },
                { title: "Upload Date", field: "date", editable: "never" },
                // { title: 'Document Type', field: 'type', editable: 'never' },
                // { title: 'Status', field: 'status_id',lookup: { 1: 'Unconfirmed', 2: 'Denied', 3: 'Accepted'} },
              ]}
              data={fileList ? fileList : []}
              options={{
                paging: false,
                selection: true,
                filtering: true,
                exportButton: true,
                doubleHorizontalScroll: true,
                emptyRowsWhenPaging: false,
                headerStyle: {
                  backgroundColor: "#3F51B5",
                  color: "#FFF",
                },
                rowStyle: {
                  backgroundColor: "#EEE",
                },
              }}
              onSelectionChange={(rows) => {
                onDocumentSelect(rows);
              }}
            />
          ) : (
            <Alert severity="warning">Select a user to get documents.</Alert>
          )}
        </Grid>
        <form onSubmit={retriveDocumentHandler} method="post">
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            spacing={1}
            className={classes.formControl}
          >
            <Grid item xs={12}>
              <Typography variant="h6">Send Documents To Admin</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                // margin="normal"
                name="email"
                label="Admin Email"
                type="email"
                id="email"
                autoComplete="email"
                required
                fullWidth
                margin="dense"
                value={email}
                onChange={onValueChanged}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                type="submit"
                variant="contained"
                color="default"
                margin="dense"
                disabled={loading || !selectedDocs}
              >
                Retrive
              </Button>
            </Grid>
          </Grid>
        </form>
        {false && <form onSubmit={documentStatusHandler} method="post">
          <Grid
            container
            item
            direction="row"
            alignItems="center"
            spacing={1}
            className={classes.formControl}
          >
            <Grid item xs={12}>
              <Typography variant="h6">Change Document Status</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                variant="outlined"
                margin="dense"
                id="docStatus"
                name="docStatus"
                value={docStatus}
                onChange={onValueChanged}
              >
                <MenuItem value={0}>Select</MenuItem>
                {statusTypes &&
                  statusTypes.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.short_desc}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="default"
                margin="dense"
                size="large"
                disabled={loading || !selectedDocs}
              >
                Change
              </Button>
            </Grid>
          </Grid>
        </form>}
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = ({ authReducer, adminReducer }) => {
  return {
    loading: authReducer.loading,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,

    adminData: adminReducer.adminDetails,
    statusTypes: adminReducer.statusTypes,
    fileList: adminReducer.fileList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDocuments: (data) => dispatch(actionCreator.getDocuments(data)),
    retriveDocuments: (data) => dispatch(actionCreator.retriveDocuments(data)),
    updateDocumentStatus: (data) =>
      dispatch(actionCreator.updateDocumentStatus(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RetriveDocs);
