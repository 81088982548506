export const getPlanFromId = (plans, id) => {
  if (plans) {
    let plan = plans.filter((planData) => planData.id === id);
    return plan;
  }
  return {};
};

export const getPlanFromPlanName = (plans, short_description) => {
  if (plans) {
    let plan = plans.filter(
      (planData) => planData.short_description === short_description
    );
    return plan;
  }
  return {};
};

export const getPlanLookUp = (plans) => {
  if (plans) {
    let lookup = {};
    plans.map((planData) => {
      if (planData.can_select)
        lookup[planData.id] = planData.short_description;
    });
    return lookup;
  }
  return {};
};
