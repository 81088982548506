import React, {useState ,useEffect,forwardRef} from 'react';
import { connect } from "react-redux";
import { withStyles, makeStyles } from '@material-ui/core/styles';

import {AddBox,ArrowDownward,Check,ChevronLeft,ChevronRight,Clear,Refresh,
    DeleteOutline,Edit,FilterList,FirstPage,LastPage,Remove,SaveAlt,Search,ViewColumn} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import {Grid,Typography,Button,Select,MenuItem,TextField,Collapse} from '@material-ui/core';
import MaterialTable from "material-table";
import RetriveDocs from "./retriveDocs";
import MailButton from "./mailButton";


import * as actionCreator from "../../../store/action/index";

const useStyles = makeStyles((theme) =>({
    formControl: {
        marginTop: theme.spacing(2),
    },
}));

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const SecurityTable = (props) => {
  
  const {loading,errorMessage,successMessage,adminData,OnCleaUp,sendDocumentsCourt,
    securityUsers,fetchAllSecurityUsers,onSetSecurityKycStatus} = props;

  const [subscribers, setSubscribers] = useState([]);
  const [kycStatus, setKycStatus] = useState(0);
  const [onlySubscriber, setOnlySubscriber] = useState(null);
  const [email, setEmail] = useState(adminData.email);
  const [mailUsers, setMailUsers] = useState("");


    useEffect(
    () => {
      if(!securityUsers){
        fetchAllSecurityUsers();
      }
      return ()=>{
        OnCleaUp();
      }
    },
    [OnCleaUp,securityUsers,fetchAllSecurityUsers]
  );

  // const onValueChanged = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   if(name == "kycStatus"){
  //     setKycStatus(value);
  //   }
  // }

  const onUserSelect = (users) => {
    let userIds = [];
    let mailIds = "";
    if(users.length == 1){
      setOnlySubscriber(users[0]);
    }else{
        setOnlySubscriber(null);
    }
    users.forEach((user,index) => {
      userIds.push(user.id);
      if(index == 0){
        mailIds = user.email;
      }else{
        mailIds = mailIds + "," + user.email;
      }
    });
    setMailUsers(mailIds);
    setSubscribers(userIds);
  }

  // const kycUpdateHandler = (e) => {
  //   e.preventDefault();
  //   if(kycStatus){
  //     onSetSecurityKycStatus({
  //       userList:subscribers,
  //       kycStatus:kycStatus
  //     })
  //   }
  // }

  const handleCourtMail = () => {
    sendDocumentsCourt({
      user:onlySubscriber
    });
  }

  const classes = useStyles();

  return (
    <React.Fragment>
            <MaterialTable
                icons={tableIcons}
                title="Data Claim Users"
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Email', field: 'email'},
                    { title: 'Cost Paid', field: 'cost_paid'},
                    { title: 'Last Update Date', field: 'date'},
                    { title: 'Last Update Time', field: 'time'},
                    { title: 'KYC Document Count', field: 'kyc_count'},
                    { title: 'Aggrement Count', field: 'aggrements_count'},
                    { title: 'Status', field: 'step_id',lookup: { 0: 'Not-Subscribed', 1: 'Paid', 2: 'Verified', 3: 'Expired', 4: 'Unsubscribed' } },
                ]}
                data={securityUsers ? securityUsers:[]}
                actions={[
                  {
                    icon: () => {return <Refresh/>},
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () => fetchAllSecurityUsers(),
                  }
                ]}
                options={{
                    pageSize:20,
                    pageSizeOptions:[10,20,50],
                    selection: true,
                    filtering: true,
                    exportButton: true,
                    doubleHorizontalScroll:true,
                    emptyRowsWhenPaging:false,
                    padding:'dense',
                    headerStyle: {
                        backgroundColor: '#3F51B5',
                        color: '#FFF'
                      },
                    rowStyle: {
                        backgroundColor: '#EEE',
                      }
                }}
                onSelectionChange={(rows) => {
                    onUserSelect(rows);
                }}
            />
            <Grid container>
              <Grid item xs={12}>
                <Collapse in={successMessage.length > 0}>
                    <Alert severity="success">
                        {successMessage}
                    </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={errorMessage.length > 0}>
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                </Collapse>
              </Grid> 
            </Grid> 

            {/* <form onSubmit={kycUpdateHandler} method="post">
              <Grid container item direction="row" alignItems="center" spacing={1} className={classes.formControl}> 
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Change Security KYC Status
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Select                
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        id="kycStatus"
                        name="kycStatus"
                        value={kycStatus}
                        onChange={onValueChanged}
                        >
                          <MenuItem value={0}>Select</MenuItem>
                          <MenuItem value={1}>Paid</MenuItem>
                          <MenuItem value={2}>Verified</MenuItem>
                          <MenuItem value={3}>Expired</MenuItem>
                          <MenuItem value={4}>Unsubscribe</MenuItem>
                      </Select>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                      <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="default"
                          margin="dense"
                          size="large"
                          disabled={loading || !subscribers.length > 0}
                      >
                          Change
                      </Button>
                  </Grid>
              </Grid>
            </form> */}

            <RetriveDocs user={onlySubscriber}/>

            <Grid container item xs={12} className={classes.formControl}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Send Request To Court
                </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Button
                      fullWidth
                      variant="contained"
                      color="default"
                      margin="dense"
                      size="large"
                      disabled={loading || !onlySubscriber}
                      onClick={() => {handleCourtMail();}}
                  >
                      Send to court
                  </Button>
              </Grid>
            </Grid>

            <MailButton email={mailUsers}/>

    </React.Fragment>
  );
}

const mapStateToProps = ({ authReducer,adminReducer }) => {
    return {
        loading: authReducer.loading,
        errorMessage: authReducer.errorMessage,
        successMessage: authReducer.successMessage,

        securityUsers:adminReducer.securityUsers,
        adminData:adminReducer.adminDetails,
    };
};
const mapDispatchToProps = dispatch => {
    return {
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),

    fetchAllSecurityUsers: () => dispatch(actionCreator.fetchAllSecurityUsers()),
    onSetSecurityKycStatus: (data) => dispatch(actionCreator.onSetSecurityKycStatus(data)),
    sendDocumentsCourt: (data) => dispatch(actionCreator.sendDocumentsCourt(data)),
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(SecurityTable)
