import React, { useState ,useEffect} from 'react';
import { connect } from "react-redux";
import {Switch,Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {AppBar,Tab,Tabs,Link,Grid,Box,Typography,Container, Collapse,Paper } from '@material-ui/core';
import { Alert,Skeleton } from '@material-ui/lab';

import * as actionCreator from "../../../store/action/index";
import Copyright from "../../layout/Copyright";
import MenuAppBar from "../../layout/MenuAppBar";
import Login from "./login";
import SearchAndAction from "./search";
import AdminTable from "./adminTable";
import SecurityTable from "./securityTable";

const TabPanel = (props)=>{
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <React.Fragment>
          {children}
        </React.Fragment>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index)=> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding:theme.spacing(3)
  },
  centerPaper: {
    marginTop: theme.spacing(8)
  },
}));

const Admin = (props) => {
  
  const {OnCleaUp,loading,history,checkAdmin,isAdmin,adminChecking,access,statusTypes,fetchStatusList} = props;

  const [value, setValue] = useState(0);

  useEffect(
    () => {
      if(adminChecking && !isAdmin){
        checkAdmin();
      }
      if(!adminChecking && !isAdmin){
        history.push("/LogIn")
      }
      if(!statusTypes){
        fetchStatusList();
      }
      return ()=>{
        OnCleaUp();
      }
    },
    [isAdmin,OnCleaUp,adminChecking,history,checkAdmin,statusTypes,fetchStatusList]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  const classes = useStyles();

  return (
    <React.Fragment>
      <MenuAppBar/>
      {!adminChecking && isAdmin ?
        <React.Fragment>
              {!access ? 
                <Login/>
              :
                <Container component="main">
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="Admin Tabs">
                      <Tab label="Coin Claim Users" {...a11yProps(0)} />
                      {/* <Tab label="Data Claim Users" {...a11yProps(1)} /> */}
                      <Tab label="Admin Controls" {...a11yProps(2)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <SearchAndAction/>
                  </TabPanel>
                  {/* <TabPanel value={value} index={1}>
                    <SecurityTable/>
                  </TabPanel> */}
                  <TabPanel value={value} index={1}>
                    <AdminTable/>
                  </TabPanel>
                </Container>
              }
            <Box mt={5}> 
              <Copyright />
            </Box>
        </React.Fragment>
        :<Skeleton variant="rect" width="100%" height={100} />
      }
    </React.Fragment>
  );
}

const mapStateToProps = ({ authReducer,adminReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn :authReducer.isLoggedIn,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,

    isAdmin: adminReducer.isAdmin,
    adminChecking : adminReducer.adminChecking,
    access: adminReducer.access,
    adminDetails:adminReducer.adminDetails,
    statusTypes:adminReducer.statusTypes,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),
    fetchStatusList: () => dispatch(actionCreator.fetchStatusList()),
    checkAdmin: () => dispatch(actionCreator.checkIfAdminAsync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin)