import * as actionTypes from "../action/actionTypes";
import { updateObject } from "../../asset/utility";

const initialState = {
  isAdmin: false,
  adminChecking: true,
  access:false,
  adminToken:null,
  adminDetails:null,
  userData:[],
  admins:null,
  securityUsers:null,
  fileList:null,
  statusTypes : null,
  courtData : null,
};

//# HERE IS THE REDUCER OR CASE'S
const adminReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.SET_ADMIN:
            return setAdmin(state,payload);
        case actionTypes.GET_ADMIN:
            return getAdmin(state,payload);
        case actionTypes.GET_USERS:
            return getUsers(state,payload);
        case actionTypes.GET_ADMINS:
            return getAdmins(state,payload);
        case actionTypes.GET_SECURITY_USERS:
            return setSecurityUser(state,payload);
        case actionTypes.SET_FILE_LIST:
            return setFileList(state,payload);
        case actionTypes.SET_STATUS_LIST:
            return setStatusList(state,payload);
        case actionTypes.SET_COURT_LIST:
            return setCourtList(state,payload);
      default:
        return state;
    }
  };
  
// /*--- CASES ARE HERE DEFINED */
// // UTILITY FUNCTION CALCULATE UTILIZATION

const getAdmin = (state,data) => {
    const {status,admin,token} = data;
    return updateObject(state, {
        access:status,
        adminDetails:admin,
        adminToken:token
    });
};

const setAdmin = (state,data) => {
    const {status} = data;
    return updateObject(state, {
        isAdmin:status,
        adminChecking: false
    });
};

const getUsers = (state,data) => {
    const {status,users} = data;
    return updateObject(state, {
        userData:users
    });
};

const getAdmins = (state,data) => {
    const {status,admins} = data;
    return updateObject(state, {
        admins:admins
    });
};

const setSecurityUser = (state,data) => {
    const {securityUsers} = data;
    return updateObject(state, {
        securityUsers:securityUsers
    });
};

const setFileList = (state,data) => {
    const {files} = data;
    return updateObject(state, {
        fileList:files
    });
};

const setStatusList = (state,data) => {
    const {status} = data;
    return updateObject(state, {
        statusTypes:status
    });
};

const setCourtList = (state,data) => {
    const {courtData} = data;
    return updateObject(state, {
        courtData:courtData
    });
};

export default adminReducer;