import React, { useState} from 'react';
import { connect } from "react-redux";


import { makeStyles } from '@material-ui/core/styles';
import {Button,TextField,Link,Grid, Collapse,Container } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import * as actionCreator from "../../../store/action/index";

const useStyles = makeStyles((theme) => ({
  centerPaper: {
    marginTop: theme.spacing(8)
  },
}));

const Login = (props) => {
  
  const {loading,errorMessage,adminLogin} = props;
  
  const [adminPassword, setPassword] = useState("");

  const onPasswordValueChanged = (e) => {
    let value = e.target.value;
    setPassword(value);
  }

  const loginHandler = (e) => {
    e.preventDefault();
    adminLogin(adminPassword);
  }
 
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container component="main">
        <form onSubmit={loginHandler} method="post">
          <Grid container direction="column" justify="center" alignItems="center" className={classes.centerPaper}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    name="password"
                    label="Admin Password"
                    type="password"
                    id="password"
                    autoComplete="admin-password"
                    value={adminPassword}
                    onChange={onPasswordValueChanged}
                />
            </Grid>
            <Grid item xs={12}>
                <Collapse in={errorMessage.length > 0}>
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                >
                    Log In
                </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = ({ authReducer,adminReducer }) => {
  return {
    loading: authReducer.loading,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    adminLogin: (password) => dispatch(actionCreator.adminLoginAsync(password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)