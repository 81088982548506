import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import {
  Done,
  Close,
  Check,
  PublishSharp,
  CloudUpload,
  Folder,
  CheckCircle,
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import {
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  FormControl,
  Collapse,
  Slide,
  AppBar,
  Toolbar,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { PayPalButton } from "react-paypal-button-v2";
import { PAYPAL_CLIENT_ID } from "../../../asset/api";
import Spinner from "../../layout/Spinner/Spinner";

import * as actionCreator from "../../../store/action/index";

const useStyles = makeStyles((theme) => ({
  rightSide: {
    marginLeft: "auto",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  input: {
    display: "none",
  },
  column: {
    maxWidth: "50%",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  icon: {
    fontSize: 30,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentDialog = (props) => {
  const { loading, status, plan, onDialogClose, setSecurityPlanPayment } =
    props;

  const onPaymentComplete = async (cost) => {
    await setSecurityPlanPayment({
      cost_paid: cost,
    });
    onDialogClose(false);
  };

  const classes = useStyles();

  return (
    <Dialog
      open={status}
      fullScreen
      onClose={onDialogClose.bind(this, false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onDialogClose.bind(this, false)}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Payments
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={onDialogClose.bind(this, false)}
          >
            close
          </Button>
        </Toolbar>
      </AppBar>

      <Grid container justify="center" spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h6">{plan.planDescription}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" component="p">
            {plan.planDescription}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h4" color="textSecondary" align="center">
            €{plan.costPaid}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <PayPalButton
            amount={plan.costPaid}
            currency="EUR"
            onSuccess={(details, data) => {
              // OPTIONAL: Call your server to save the transaction
              alert(
                "Transaction completed by " + details.payer.name.given_name
              );
              onPaymentComplete(plan.costPaid);
            }}
            options={{
              clientId: PAYPAL_CLIENT_ID,
              currency: "EUR",
            }}
            style={{
              shape: "rect",
              color: "gold",
              layout: "vertical",
              label: "paypal",
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

export const QontoStepIcon = (props)=>{
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
};

const ProcessStepper = (props) => {
  const { activeStep } = props;

  const classes = useStyles();

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<QontoConnector />}
    >
      <Step key={1}>
        <StepLabel StepIconComponent={QontoStepIcon}>
          Agreement and Payment
        </StepLabel>
      </Step>
      <Step key={2}>
        <StepLabel StepIconComponent={QontoStepIcon}>
          Upload Documents
        </StepLabel>
      </Step>
      <Step key={3}>
        <StepLabel StepIconComponent={QontoStepIcon}>Approved</StepLabel>
      </Step>
    </Stepper>
  );
};

const SecurityPlan = (props) => {
  const {
    profile,
    status,
    loading,
    securityPlan,
    setSecurityPlanPayment,
    onGetSecurityPlan,
    securityFiles,
    onGetUserSecurityFiles,
    onGetAccountsAsync,
    accounts,
    onUploadSecurityFile,
    errorMessage,
    successMessage,
  } = props;

  const [agreed, setAgreed] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [step, setStep] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState({
    planName:
      "Register for claim for breach of privacy and for supply of personal data",
    planDescription:
      "Cost is €10 plus 30% of any settlement from claim against the liquidator and their lawyers for breach of privacy or negligence claim.",
    costPaid: 10,
  });
  const [uploadFile, setUploadFile] = useState(null);

  useEffect(() => {
    if (!securityPlan) {
      onGetSecurityPlan();
    } else {
      if (securityPlan.short_desc) {
        let stepValue = 0;

        if (securityPlan.short_desc == "Paid") {
          stepValue = 1;
        } else if (securityPlan.short_desc == "Verified") {
          stepValue = 2;
        } else if (securityPlan.short_desc == "Expired") {
          stepValue = 3;
        }
        setStep(stepValue);
      }
    }
    if (!accounts) {
      onGetAccountsAsync();
    }
    if (!securityFiles) {
      onGetUserSecurityFiles();
    }
  }, [
    securityPlan,
    onGetSecurityPlan,
    accounts,
    onGetAccountsAsync,
    securityFiles,
    onGetUserSecurityFiles,
  ]);

  const classes = useStyles();

  const onDialogCloseHandler = (status) => {
    setOpenDialog(status);
  };

  const onFileChange = (e) => {
    setUploadFile(e.target.files[0]);
  };

  const fileUpload = (e) => {
    e.preventDefault();
    onUploadSecurityFile({
      file: uploadFile,
    });
  };

  const stepContent = () => {
    switch (step) {
      case 1:
        return (
          <Grid item container justify="center" alignItems="center" spacing={3}>
            <Grid item xs={12}>
              <Collapse in={successMessage.length > 0}>
                <Alert severity="success">{successMessage}</Alert>
              </Collapse>
              <Collapse in={errorMessage.length > 0}>
                <Alert severity="error">{errorMessage}</Alert>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Alert severity="info">
                The documents are/will be sent to{" "}
                <strong>{profile.email}</strong>.
              </Alert>
            </Grid>
            <form onSubmit={fileUpload} method="post">
              <Grid
                item
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={6}>
                  <FormControl>
                    <input
                      accept="image/*,.pdf,.doc,.docx"
                      className={classes.input}
                      id="file"
                      name="file"
                      onChange={onFileChange}
                      type="file"
                    />
                    <label htmlFor="file">
                      <IconButton aria-label="upload picture" component="span">
                        <PublishSharp />
                      </IconButton>
                      {uploadFile ? (
                        <Typography
                          variant="caption"
                          display="block"
                          className={classes.column}
                          gutterBottom
                        >
                          {uploadFile.name}
                        </Typography>
                      ) : null}
                    </label>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl>
                    <Button
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={loading || !Boolean(uploadFile)}
                      startIcon={<CloudUpload />}
                    >
                      Upload
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h6">Previous Uploads</Typography>
              </Grid>
              <Grid item>
                {!loading && securityFiles ? (
                  <div className={classes.demo}>
                    <List dense={false}>
                      {securityFiles.map((item) => (
                        <ListItem key={item.id} alignItems="flex-start">
                          <ListItemAvatar>
                            <Avatar>
                              <Folder />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.column}
                                  color="textPrimary"
                                >
                                  {item.name}
                                </Typography>
                              </React.Fragment>
                            }
                            secondary={new Date(item.date_time)
                              .toISOString()
                              .substring(0, 10)}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <Alert severity="info">Files are under processing.</Alert>
                  </div>
                ) : (
                  <Alert severity="warning">No Files uploaded till now</Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <CheckCircle
                style={{ color: green[500] }}
                className={classes.icon}
              />
            </Grid>
            <Grid item>
              <Typography component="span" variant="h5">
                Documents Verified !!
              </Typography>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Grid item container direction="column" alignItems="center">
            {securityPlan && securityPlan.step_id == 3 ? (
              <Grid item>
                <Alert severity="error">
                  <strong>{securityPlan.short_desc}!! </strong>
                  {securityPlan.long_desc}
                </Alert>
              </Grid>
            ) : null}
            <Grid item>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Done />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    Agree to appoint Accendos Group NZ Limited as Power of
                    Attorney
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Done />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    Agree to have Accendos Group NZ Limited to obtain copy of
                    the data that was released as part of the High Court of
                    Christchurch data breach in April 2020.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <Done />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    Agreed to be added to any class action related to this data
                    breach.
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value={status} color="primary" />}
                label="I agree to the above conditions."
                onChange={() => setAgreed(!agreed)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={!agreed}
                className={classes.rightSide}
                onClick={() => setOpenDialog(true)}
              >
                Proceed To Payment
              </Button>
            </Grid>
          </Grid>
        );
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <ProcessStepper activeStep={step < 3 ? step : 0} />
          </Grid>
          {accounts &&
          profile &&
          profile.firstName &&
          profile.lastName &&
          profile.address &&
          profile.phone &&
          profile.email ? (
            stepContent()
          ) : (
            <React.Fragment>
              {!accounts ? (
                <Alert variant="filled" severity="warning">
                  <strong component={RouterLink} to="/AccountsInfo">
                    Cryptopia Accounts
                  </strong>{" "}
                  data is required to continue,Please fill it in the{" "}
                  <strong>Cryptopia Accounts Info</strong> page.
                </Alert>
              ) : (
                <Alert variant="filled" severity="warning">
                  Please fill the missing data in the{" "}
                  <strong component={RouterLink} to="/Profile">
                    Profile
                  </strong>{" "}
                  page to continue.
                </Alert>
              )}
            </React.Fragment>
          )}
        </Grid>
      )}
      {agreed ? (
        <PaymentDialog
          loading={loading}
          status={openDialog}
          onDialogClose={onDialogCloseHandler}
          plan={selectedPlan}
          setSecurityPlanPayment={setSecurityPlanPayment}
        />
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = ({ authReducer, userReducer }) => {
  return {
    loading: authReducer.loading,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,
    profile: userReducer.profile,
    securityPlan: userReducer.securityPlan,
    accounts: userReducer.accounts,
    securityFiles: userReducer.securityFiles,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setSecurityPlanPayment: (data) =>
      dispatch(actionCreator.setSecurityPlanPayment(data)),
    onGetUserSecurityFiles: () =>
      dispatch(actionCreator.getUserSecurityFilesAsync()),
    onGetSecurityPlan: () => dispatch(actionCreator.getSecurityPlanAsync()),
    onUploadSecurityFile: (data) =>
      dispatch(actionCreator.uploadSecurityFile(data)),
    onGetAccountsAsync: () => dispatch(actionCreator.getAccountsAsync()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityPlan);
