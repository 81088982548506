import React ,{ useState ,useEffect}  from 'react';
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import {Avatar,Button,CssBaseline,TextField,FormControlLabel,
        Checkbox,Link,Grid,Box,Typography,Container,Collapse,Paper  } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import Logo from "../layout/Logo";
import MenuAppBar from "../layout/MenuAppBar";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        padding:theme.spacing(3)
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 3),
    },
    hide:{
      display:'none'
    }
}));


const ForgetPassword = (props) => {
  const [userEmail, setUserEmail] = useState("");
  const [emailError, setEmailError] = useState({
    status:false,
    message:""
  });
  const {OnCleaUp,onIsLogInAsync,loading,isLoggedIn,onGetProfile,profile} = props;

  useEffect(
    () => {
      if(!profile && isLoggedIn){
        onGetProfile();
      }else if(profile){
        setUserEmail(profile.email);
      }
      return ()=>{
        OnCleaUp();
      }
    },
    [OnCleaUp,onGetProfile,profile,isLoggedIn]
  );

  const validateEmail = (email) => {
    let emailErrorCopy = { ...emailError };
    let emailRegEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
    if(email.trim().length===0){
      emailErrorCopy.message = "This field is required";
      emailErrorCopy.status = false;
    } else if(!emailRegEX.test(email.trim())){
      emailErrorCopy.message = "please enter a valid email address";
      emailErrorCopy.status = false;
    }else{
      emailErrorCopy.message = "";
      emailErrorCopy.status = true;
    }
    setEmailError(emailErrorCopy);
  }

  const onInputValueChanged = (e) => {
    let value = e.target.value;
    setUserEmail(value);
    validateEmail(value);
  }

  const forgetPasswordHandler = (e) => {
    e.preventDefault();
    props.forgetPasswordAsync(userEmail);
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      {isLoggedIn ? <MenuAppBar/> : null}
      <Container component="main" maxWidth="xs">
      <Grid container alignItems="flex-end" justify="center" className={props.isLoggedIn ? classes.hide : null}>
        <Logo />
      </Grid>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <form className={classes.form} onSubmit={forgetPasswordHandler} method="post">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                error = {emailError.message.length > 0}
                helperText={emailError.message}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={userEmail}
                onChange={onInputValueChanged}
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={props.error}>
                <Alert severity="error">
                  {props.errorMessage}
                </Alert>
              </Collapse>
              <Collapse in={props.successMessage.length > 0}>
                <Alert severity="success">
                  {props.successMessage}
                </Alert>
              </Collapse>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-start" spacing={1}>
            <Grid item xs={12} sm={6}>
              <Typography component="h1" variant="subtitle2" color="textSecondary" className={classes.submit}>
                You will recieve a reset request
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={props.loading}
                //className={classes.submit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          <Grid container className={props.isLoggedIn ? classes.hide : null} spacing={1}>
            <Grid item xs={12} sm={6}>
              <Link component={RouterLink} to="/login" variant="body2">
                Log In Instead
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link component={RouterLink} to="/register" variant="body2">
                Register New Account
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </React.Fragment>
  );
}

const mapStateToProps = ({ authReducer,userReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn :authReducer.isLoggedIn,
    error: authReducer.error,
    successMessage:authReducer.successMessage,
    errorMessage: authReducer.errorMessage,

    profile : userReducer.profile
  };
};
const mapDispatchToProps = dispatch => {
  return {
    forgetPasswordAsync: userEmail => dispatch(actionCreator.ForgetPasswordAsync(userEmail)),
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),

    onGetProfile: () => dispatch(actionCreator.GetProfileAsync())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)