import React, { forwardRef, useEffect, useState } from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import * as actionCreator from "../../../store/action/index";

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { connect } from "react-redux";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyItems: "center",
  },
  table: {
    minWidth: "50%",
  },
  sideText: {
    width: "100%",
    textAlign: "center",
  },
}));

const DetailPanel = ({ email, accounts, getCryptopiaDetail }) => {
  const classes = useStyles();
  const [croptopiaData, setCroptopiaData] = useState(false);
  useEffect(() => {
    getCryptopiaDetail(email, setCroptopiaData);
  }, []);
  console.log("croptopiaData: ", croptopiaData);
  return (
    <Grid container>
      {typeof croptopiaData === "object" ? (
        Object.keys(croptopiaData).length ? (
          <Grid item xs={6}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {Object.keys(croptopiaData).map((column, index) => (
                    <StyledTableCell align="center" key={index}>
                      {column}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(croptopiaData).map((column, index) => (
                  <StyledTableCell align="center" key={index}>
                    {croptopiaData[column]}
                  </StyledTableCell>
                ))}
                {/* {accounts &&
                  accounts.map((row) => (
                    <StyledTableRow key={row.email}>
                      <StyledTableCell component="th" scope="row">
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        ${row.value}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))} */}
              </TableBody>
            </Table>
          </Grid>
        ) : (
          <Typography className={classes.sideText}>
            User doesn't exist in cryptopia.
          </Typography>
        )
      ) : (
        <Typography className={classes.sideText}>
          Searching for user.
        </Typography>
      )}
    </Grid>
  );
};
const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCryptopiaDetail: (email, setCroptopiaData) =>
      dispatch(actionCreator.getCryptopiaDetail(email, setCroptopiaData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailPanel);
