import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";

const StepperImage = ({ image }) => {
    const [imageOpen, setImageOpen] = React.useState(false)
    return <>
        <Dialog
            open={imageOpen}
            onClose={() => setImageOpen(!imageOpen)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ style: { minWidth: "96vw" } }}
        >

            <IconButton
                aria-label="close"
                onClick={() => setImageOpen(!imageOpen)}
                style={{
                    position: "absolute",
                    right: 4,
                    top: 0,
                    color: "grey",
                }}
            >
                <CloseIcon />
            </IconButton>

            <DialogContent onClick={() => setImageOpen(!imageOpen)}>
                <img src={image} width="100%" />
            </DialogContent>
        </Dialog>
        <img src={image} style={{ width: "100%", border: "1px solid rgba(0,0,0,0.1)" }} onClick={() => setImageOpen(!imageOpen)} />
    </>
}
export default StepperImage;