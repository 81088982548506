import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { CloudUpload, ExpandLess, ExpandMore, PublishSharp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar, Select, MenuItem, Button, CssBaseline, TextField, FormControl,
  Grid, Box, Typography, Container, Collapse, Paper, InputLabel, IconButton,
  List, ListItem, ListItemAvatar, ListItemText, CircularProgress
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FolderIcon from '@material-ui/icons/Folder';
import { Alert } from '@material-ui/lab';

import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import MenuAppBar from "../layout/MenuAppBar";



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3)
  },
  formControl: {
    width: "100%",
  },
  form: {
    //width: '100%', // Fix IE 11 issue.
    display: 'flex',
    marginTop: theme.spacing(3),
    justify: "space-between",
    alignItems: "center",
  },
  input: {
    display: "none"
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginRight: theme.spacing(1),
    display: "inline"
  },
  column: {
    maxWidth: "50%"
  },
}));

const Uploads = (props) => {
  const { OnCleaUp, history, onIsLogInAsync, isLoggedIn, loading, getUserFiles, files, onUploadFile,
    onGetProfile, profile, isVerified, onResendAgreement } = props;

  const [type, setType] = useState(1);
  const [uploadFile, setUploadFile] = useState(null);
  const [showUploadForm, setShowUploadForm] = useState(false);

  useEffect(
    () => {
      if (!files) {
        getUserFiles();
      }
      if (!profile) {
        onGetProfile();
      }
      return () => {
        OnCleaUp();
      }
    },
    [OnCleaUp, getUserFiles, files]
  );

  const toggleForm = e => {
    setShowUploadForm(!showUploadForm);
  }

  const onTypeValueChanged = (e) => {
    let value = e.target.value;
    setType(value);
  }

  const onFileChange = (e) => {
    setUploadFile(e.target.files[0]);
  }

  const fileUpload = (e) => {
    e.preventDefault();
    onUploadFile({
      file: uploadFile,
      type: type
    });
  };

  const resendAgreement = (e) => {
    onResendAgreement();
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <MenuAppBar />
      <Container component="main" maxWidth="sm">
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                Previous Uploads
              </Typography>
              <div className={classes.demo}>
                <List dense={false}>
                  {!loading && files ? files.map(item => (
                    <ListItem key={item.id} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar>
                          <FolderIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.column}
                              color="textPrimary"
                            >
                              {item.name}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={item.type}
                      />
                      <ListItemText
                        edge="end"
                        primary={new Date(item.date_time).toISOString().substring(0, 10)}
                      />
                    </ListItem>
                  )) :
                    <Alert severity="warning">
                      No Files uploaded till now
                    </Alert>
                  }
                </List>
              </div>
            </Grid>
            <Grid item xs={12} >
              <Typography variant="h6" className={classes.title}>
                Upload New
              </Typography>
              <IconButton color="default" aria-label="upload file" onClick={toggleForm} component="span">
                {showUploadForm ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              <Collapse in={showUploadForm}>
                <form onSubmit={fileUpload} method="post">
                  <Grid container justify="space-evenly" alignItems="center" spacing={1}>
                    <Grid item xs={12} md={4}>
                      <FormControl className={classes.formControl}>
                        <input accept="image/*,.pdf,.doc,"
                          className={classes.input}
                          id="file"
                          name="file"
                          onChange={onFileChange}
                          type="file" />
                        <label htmlFor="file">
                          <IconButton aria-label="upload picture" component="span">
                            <PublishSharp />
                          </IconButton>
                          {uploadFile ?
                            <Typography variant="caption" display="block" className={classes.column} gutterBottom>
                              {uploadFile.name}
                            </Typography>
                            : null}
                        </label>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <FormControl className={classes.formControl}>
                        <Select
                          id="fileType"
                          name="fileType"
                          value={type}
                          onChange={onTypeValueChanged}
                          variant="outlined"
                          fullWidth
                        >
                          <MenuItem value={1}>Identification</MenuItem>
                          <MenuItem value={2}>Proof of Address</MenuItem>
                          <MenuItem value={3}>Agreement</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl className={classes.formControl}>
                        <Button
                          size="large"
                          type="submit"
                          variant="contained"
                          color="primary"
                          fullWidth
                          disabled={loading || !Boolean(uploadFile)}
                          startIcon={<CloudUpload />}
                        >
                          Upload
                        </Button>
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={props.successMessage.length > 0}>
                <Alert severity="success">
                  {props.successMessage}
                </Alert>
              </Collapse>
              <Collapse in={props.errorMessage.length > 0}>
                <Alert severity="error">
                  {props.errorMessage}
                </Alert>
              </Collapse>
            </Grid>
            {profile ?
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading || !isVerified}
                  disableElevation
                  onClick={resendAgreement}
                >
                  Resend agreements to email
                </Button>
              </Grid>
              : null}
            {isVerified ? null :
              <Grid item xs={12}>
                <Alert severity="error">
                  Please verify email to activate "Resend agreements to email" facility
                </Alert>
              </Grid>
            }
          </Grid>
        </Paper>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = ({ authReducer, userReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn: authReducer.isLoggedIn,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,

    files: userReducer.files,
    profile: userReducer.profile,
    isVerified: userReducer.isVerified
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),

    onGetProfile: () => dispatch(actionCreator.GetProfileAsync()),
    onUploadFile: (data) => dispatch(actionCreator.UploadFileAsync(data)),
    getUserFiles: () => dispatch(actionCreator.getUserFilesAsync()),
    onResendAgreement: () => dispatch(actionCreator.resendAgreementAsync()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Uploads)