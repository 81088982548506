import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Register from './components/auth/Register';
import LogIn from './components/auth/LogIn';
import ForgetPassword from './components/auth/ForgetPassword';
import ResetPassword from './components/auth/ResetPassword';
import VerifyEmail from './components/auth/VerifyEmail';
import Landing from './components/dashboard/Landing';
// import Landing from './components/dashboard/Landing';
import Profile from './components/dashboard/Profile';
import Plans from './components/dashboard/Plans';
import AccountsInfo from './components/dashboard/AccountsInfo';
import Uploads from './components/dashboard/Uploads';
import Payments from './components/dashboard/Payments';
import { CssBaseline } from '@material-ui/core';
import Admin from './components/dashboard/admin/home';
import ChartIcon from "./components/chatbot/chatbot";


import * as actionCreator from "./store/action/index";
import FinalSettlement from './components/dashboard/FinalSettlement';

const App = (props) => {
  const { OnCleaUp, onIsLogInAsync, isLoggedIn, checking } = props;

  useEffect(() => {
    if (!isLoggedIn) {
      onIsLogInAsync();
    }
    return () => {
      OnCleaUp();
    };
  }, [isLoggedIn, OnCleaUp, onIsLogInAsync]);

  const authRoutes = (
    <Switch>
      <Route exact path="/" component={Landing}></Route>
      <Route path='/ResetPassword/:token' component={ResetPassword}></Route>
      <Route path='/ForgetPassword' component={ForgetPassword}></Route>
      <Route path='/VerifyEmail/:token' component={VerifyEmail}></Route>
      <Route path='/Profile' component={Profile}></Route>
      <Route path='/Plans' component={Plans}></Route>
      {/* <Route path='/Uploads' component={Uploads}></Route> */}
      <Route path='/Payments' component={Payments}></Route>
      <Route path='/AccountsInfo' component={AccountsInfo}></Route>
      <Route path='/Admin' component={Admin}></Route>
      <Route path='/Final-Settlement' component={FinalSettlement}></Route>
      <Redirect to='/' />
    </Switch>
  );

  const otherRoutes = (
    <Switch>
      <Route path="/register" component={Register}></Route>
      <Route path="/login" component={LogIn}></Route>
      <Route path="/ResetPassword/:token" component={ResetPassword}></Route>
      <Route path="/ForgetPassword" component={ForgetPassword}></Route>
      <Route path="/VerifyEmail/:token" component={VerifyEmail}></Route>
      <Redirect to="/login" />
    </Switch>
  );

  return (
    <React.Fragment>
      <CssBaseline />
      {checking ? (
        <Skeleton variant="rect" width="100%" height={100} />
      ) : (
        <React.Fragment>{isLoggedIn ? authRoutes : otherRoutes}</React.Fragment>
      )}
      {
        isLoggedIn ? <ChartIcon /> : null
      }
    </React.Fragment>
  );
};
const mapStateToProps = ({ authReducer, adminReducer }) => {
  return {
    checking: authReducer.checking,
    isLoggedIn: authReducer.isLoggedIn,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
