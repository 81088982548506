import * as actionTypes from "./actionTypes";
import axios from "axios";

import qs from "qs";
import { setWithExpiry, getWithExpiry } from "../../asset/api";

const API = axios.create({
  baseURL: `${process.env.REACT_APP_BASE}admin`,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

const setLoading = () => {
  return {
    type: actionTypes.SET_LOADING,
  };
};

const setStatus = (data) => {
  return {
    type: actionTypes.SET_STATUS,
    payload: data,
  };
};

const getAdmin = (data) => {
  return {
    type: actionTypes.GET_ADMIN,
    payload: data,
  };
};

export const adminLoginAsync = (password) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      password: password,
    };

    API.post("/adminLogin", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, admin, token } = res.data;
        dispatch(
          getAdmin({
            status: status,
            admin: admin,
            token: token,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

const setAdmin = (data) => {
  return {
    type: actionTypes.SET_ADMIN,
    payload: data,
  };
};

export const checkIfAdminAsync = () => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    const reqBody = {
      token: storedToken,
    };

    API.post("/checkAdmin", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setAdmin({
            status: status,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setAdmin({
            status: false,
          })
        );
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

const getUsers = (data) => {
  return {
    type: actionTypes.GET_USERS,
    payload: data,
  };
};

export const getUserData = (email) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      email: email,
    };

    API.post("/getUsers", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, users } = res.data;
        dispatch(
          getUsers({
            status: status,
            users: users,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const updateUser = (oldData, newData, searchHandler) => {
  return async (dispatch, getState) => {
    console.log("oldData: ", oldData);
    console.log("newData: ", newData);
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      email: oldData.email,
      oldData: oldData,
      newData: newData,
    };
    API.post("/updateUser", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, users } = res.data;
        // dispatch(
        //   getUsers({
        //     status: status,
        //     users: users,
        //   })
        // );
        // dispatch(
        //   setStatus({
        //     errorMessage: errorMessage,
        //   })
        // );
        searchHandler();
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const setKycStatus = ({ userList, kycStatus }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      userList: userList,
      kycStatus: kycStatus,
    };

    API.post("/setKycStatus", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(getUserData(""));
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const resetUserPassword = ({ id, email, password }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      id: id,
      email: email,
      password: password,
    };

    API.post("/resetUserPassword", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const retriveDocuments = ({
  adminEmail,
  user,
  kycDocs,
  securityDocs,
  cryptopiaDoc,
}) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      adminEmail: adminEmail,
      user: user,
      kycDocs: kycDocs,
      securityDocs: securityDocs,
      cryptopiaDoc,
    };

    API.post("/retriveDocuments", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

const getAdmins = (data) => {
  return {
    type: actionTypes.GET_ADMINS,
    payload: data,
  };
};

export const fetchAllAdmin = () => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
    };

    API.post("/getAdmins", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, admins } = res.data;
        dispatch(
          getAdmins({
            status: status,
            admins: admins,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const resetAdminPassword = ({ email, password }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      email: email,
      password: password,
    };

    API.post("/resetPassword", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const addNewAdmin = ({ email, access, enabled }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      email: email,
      access: access,
      enabled: enabled,
    };

    API.post("/addAdmin", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
        setTimeout(() => {
          dispatch(fetchAllAdmin());
        }, 3000);
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const removeAdmin = ({ id, email }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      userId: id,
      email: email,
    };

    API.post("/removeAdmin", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
        setTimeout(() => {
          dispatch(fetchAllAdmin());
        }, 3000);
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const editAdmin = ({ id, access, enabled }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      userId: id,
      access: access,
      enabled: enabled,
    };

    API.post("/editAdmin", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
        setTimeout(() => {
          dispatch(fetchAllAdmin());
        }, 3000);
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

const setSecurityUsers = (data) => {
  return {
    type: actionTypes.GET_SECURITY_USERS,
    payload: data,
  };
};

export const fetchAllSecurityUsers = () => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
    };

    API.post("/getSecurityUsers", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, users } = res.data;
        dispatch(
          setSecurityUsers({
            securityUsers: users,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const onSetSecurityKycStatus = ({ userList, kycStatus }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      userList: userList,
      kycStatus: kycStatus,
    };

    API.post("/setSecurityKycStatus", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(fetchAllSecurityUsers());
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

// export const retriveSecurityDocuments =  ({adminEmail,user}) => {

//   return async (dispatch, getState) => {
//     //# HERE MAKE ASYNC CALLS.
//     let storedToken = getWithExpiry('token');
//     let adminToken = getState().adminReducer.adminToken;
//     dispatch(setLoading());
//     const reqBody ={
//       token:storedToken,
//       adminToken:adminToken,
//       adminEmail:adminEmail,
//       user:user
//     };

//     API.post("/retriveSecurityDocuments", qs.stringify(reqBody), {
//         withCredentials: false
//       })
//       .then(res => {
//         const { status, successMessage , errorMessage} = res.data;
//         dispatch(setStatus({
//           errorMessage: errorMessage,
//           successMessage:successMessage
//         }));
//       })
//       .catch(err => {
//           console.log("axios error:- ",err);
//           dispatch(
//               setStatus({
//                 successMessage : "",
//                 errorMessage: "Error , refresh and try again !!"
//               })
//           );
//       });
//   };
// }

export const sendDocumentsCourt = ({ user }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      user: user,
    };

    API.post("/sendDocumentsCourt", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
        dispatch(fetchCourtDataList());
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

const setFileList = (data) => {
  return {
    type: actionTypes.SET_FILE_LIST,
    payload: data,
  };
};

export const getDocuments = ({ userId }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      user: userId,
    };

    API.post("/getDocuments", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, files } = res.data;
        dispatch(
          setFileList({
            files: files,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

const setStatusList = (data) => {
  return {
    type: actionTypes.SET_STATUS_LIST,
    payload: data,
  };
};

export const fetchStatusList = () => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
    };

    API.post("/getStatusList", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, statusList } = res.data;
        dispatch(
          setStatusList({
            status: statusList,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const updateDocumentStatus = ({
  kycDocs,
  SecurityDocs,
  docStatus,
  userId,
}) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      kycDocs: kycDocs,
      secDocs: SecurityDocs,
      docStatus: docStatus,
    };

    API.post("/setDocumentStatus", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          getDocuments({
            userId: userId,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

const setCourtList = (data) => {
  return {
    type: actionTypes.SET_COURT_LIST,
    payload: data,
  };
};

export const fetchCourtDataList = () => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    let superAdmin = getState().adminReducer.adminDetails.enabled || 0;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      superAdmin: superAdmin,
    };

    API.post("/getSendToCourtList", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage, courtData } = res.data;
        dispatch(
          setCourtList({
            courtData: courtData,
          })
        );
        dispatch(
          setStatus({
            errorMessage: errorMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const getCryptopiaDetail = (email, setCroptopiaData) => {
  return async (dispatch, getState) => {
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      email: email,
    };
    API.post("/getCryptopiaUser", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { successMessage, errorMessage } = res.data;
        if (res.data.data && res.data.data[0])
          setCroptopiaData(res.data.data[0]);
        else setCroptopiaData({});
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
      })
      .catch((err) => {
        setCroptopiaData({});
        console.log("err: ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const sendMailToUsers = ({ to, text }) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      mailTo: to,
      mailText: text,
    };

    API.post("/sendMailToUsers", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
      })
      .catch((err) => {
        console.log("axios error:- ", err);
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
      });
  };
};

export const sendMailToAllUsers = (request) => {
  return async (dispatch, getState) => {
    //# HERE MAKE ASYNC CALLS.
    let storedToken = getWithExpiry("token");
    let adminToken = getState().adminReducer.adminToken;
    dispatch(setLoading());
    const reqBody = {
      token: storedToken,
      adminToken: adminToken,
      ...request
    };

    return API.post("/sendMailToAllUsers", qs.stringify(reqBody), {
      withCredentials: false,
    })
      .then((res) => {
        const { status, successMessage, errorMessage } = res.data;
        dispatch(
          setStatus({
            errorMessage: errorMessage,
            successMessage: successMessage,
          })
        );
        return res.data
      })
      .catch((err) => {
        dispatch(
          setStatus({
            successMessage: "",
            errorMessage: "Error , refresh and try again !!",
          })
        );
        return err
      });
  };
};