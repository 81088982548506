import React, { useState ,useEffect} from 'react';
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles} from '@material-ui/core/styles';
import {Avatar,Button,TextField,Link,Grid,Box,Typography,Container, Collapse,Paper,
  FormControl,InputLabel,OutlinedInput,InputAdornment,IconButton} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {EditLocationOutlined} from '@material-ui/icons';
import GooglePlacesAutocomplete ,{ geocodeByPlaceId }from 'react-google-places-autocomplete';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ReactPhoneInput from 'react-phone-input-material-ui';
import MuiPhoneInput from 'material-ui-phone-number';


import * as actionCreator from "../../store/action/index";
import Copyright from "../layout/Copyright";
import MenuAppBar from "../layout/MenuAppBar";

import {GOOGLE_PLACES_ID} from "../../asset/api";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    padding:theme.spacing(3)
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  show:{
    display:'block'
  },
  hide:{
    display:'none'
  },
  field: {
    margin: '10px 0',
  },
  countryList: {
    ...theme.typography.body1,
  },
}));

const Profile = (props) => {
  const [user, setUser] = useState({
    firstName : "",
    middleName : "",
    lastName : "",
    email : "",
    address : "",
    phone : "",
  });
  const [errorMessages, setErrorMessages] = useState({
    firstNameError : "",
    middleNameError : "",
    lastNameError : "",
    emailError : "",
    addressError : ""
  });
  const [validData, setvalidData] = useState({
    validFirstName : false,
    validMiddleName : false,
    validLastName : false,
    validEmail : false,
    validAddress: false,
    validOthers: false
  });
  const[addressInput,setAddressInput] = useState(false);
  const {OnCleaUp,history,onIsLogInAsync,isLoggedIn,loading,profile,onGetProfile,isVerified} = props;

    useEffect(
    () => {
      if(profile) {
        setUser({
          firstName : profile.firstName || "",
          middleName : profile.middleName || "",
          lastName : profile.lastName || "",
          email : profile.email || "",
          address : profile.address || "",
          phone : profile.phone || ""
        });
      }else{
        onGetProfile();
      }
      return ()=>{
        OnCleaUp();
      }
    },
    [profile,onGetProfile,OnCleaUp]
  );

  const validateField = (field,value) => {
    let errorMessagesCopy = { ...errorMessages };
    let validDataCopy = { ...validData };

    if(field === "firstName" || field === "lastName" || field === "middleName"){
      if(value.trim().length===0){
        if(field === "firstName"){
          errorMessagesCopy.firstNameError = "This field is required";
          validDataCopy.validFirstName = false;
          }
          else if(field === "lastName"){
          errorMessagesCopy.lastNameError = "This field is required";
          validDataCopy.validLastName = false;
          }
          else if(field === "middleName"){
          validDataCopy.validMiddleName = true;
          }
      } else if(!/^[A-Za-z ]+$/.test(value.trim())){
        if(field === "firstName"){
          errorMessagesCopy.firstNameError = "Name should contain only alphabets";
          validDataCopy.validFirstName = false;
          }
          else if(field === "lastName"){
          errorMessagesCopy.lastNameError = "Name should contain only alphabets";
          validDataCopy.validLastName = false;
          }
          else if(field === "middleName"){
            errorMessagesCopy.middleNameError = "Name should contain only alphabets";
            validDataCopy.validMiddleName = false;
            }
      }else{
        if(field === "firstName"){
        validDataCopy.validFirstName = true;
        errorMessagesCopy.firstNameError = "";
        }
        else if(field === "lastName"){
        validDataCopy.validLastName = true;
        errorMessagesCopy.lastNameError = "";
        }
        else if(field === "middleName"){
        validDataCopy.validMiddleName = true;
        errorMessagesCopy.middleNameError = "";
        }
      }
    }else if(field === "email"){
      let emailRegEX = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
      if(value.trim().length===0){
        errorMessagesCopy.emailError = "This field is required";
        validDataCopy.validEmail = false;
      } else if(!emailRegEX.test(value.trim())){
        errorMessagesCopy.emailError = "please enter a valid email address";
        validDataCopy.validEmail = false;
      }else{
        errorMessagesCopy.emailError = "";
        validDataCopy.validEmail = true;
      }
    }else{
      validDataCopy.validOthers = true;
    }
    setErrorMessages(errorMessagesCopy);
    setvalidData(validDataCopy);
  }

  const onInputValueChanged = (e) => {
    console.log(e.target);
    let name = e.target.name;
    let value = e.target.value;
    let userCopy = { ...user };
    userCopy[name] = value;
    setUser(userCopy);
    validateField(name,value);
  }

  const onPhoneValueChanged = (value) => {
    let userCopy = { ...user };
    userCopy.phone = value;
    setUser(userCopy);
    validateField('phone',value);
  }

  const onAddressValueChanged = (value) => {
    let userCopy = { ...user };
    let validDataCopy = { ...validData };
    userCopy.address = value.description;
    validDataCopy.validOthers = true;
    setUser(userCopy);
    setvalidData(validDataCopy);
    setAddressInput(false);
  }

  const handleVerify = (e)=> {
    props.OnVerifyUserAsync({email : profile.email || ""})
  }

  const updateProfileHandler = e => {
    e.preventDefault();
    if(validData.validFirstName || validData.validMiddleName || validData.validLastName || validData.validEmail || validData.validAddress || validData.validOthers){
        props.UpdateProfileAsync(user);
    }
  };

  
  const classes = useStyles();

  return (
    <React.Fragment>
      <MenuAppBar/>
      <Container component="main" maxWidth="sm">
        <Paper className={classes.paper}>
          <Grid container alignItems="flex-end" justify="space-between" spacing={1}>
            <Grid item>
              <Typography variant="h6" align="left" display="inline">
                Update Profile
              </Typography>
            </Grid>
            <Grid item >
              <Link component={RouterLink} to="/ForgetPassword" variant="subtitle2">
                Change Password
              </Link>
            </Grid>
          </Grid>
          <form className={classes.form} onSubmit={updateProfileHandler} method="post">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error = {errorMessages.firstNameError.length > 0}
                  helperText = {errorMessages.firstNameError}
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={user.firstName}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  error = {errorMessages.middleNameError.length > 0}
                  helperText={errorMessages.middleNameError}
                  variant="outlined"
                  fullWidth
                  id="midleName"
                  label="Middle Name"
                  name="middleName"
                  autoComplete="mname"
                  value={user.middleName}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error = {errorMessages.lastNameError.length > 0}
                  helperText={errorMessages.lastNameError}
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={user.lastName}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error = {errorMessages.emailError.length > 0}
                  helperText={errorMessages.emailError}
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={user.email}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <TextField
                  // error = {errorMessages.emailError.length > 0}
                  // helperText={errorMessages.emailError}
                  variant="outlined"
                  fullWidth
                  id="phone"
                  label="Phone"
                  name="phone"
                  autoComplete="phone"
                  value={user.phone}
                  onChange={onInputValueChanged}
                /> */}

                {/* <PhoneInput
                  id="phone"
                  name="phone"
                  placeholder="phone"
                  value={user.phone}
                  onChange={onPhoneValueChanged}/> */}

                <ReactPhoneInput
                  value={user.phone}
                  onChange={onPhoneValueChanged}
                  inputClass={classes.field}
                  dropdownClass={classes.countryList}
                  component={OutlinedInput}
                  label="Phone"
                />
                
                {/* <MuiPhoneInput
                  value={user.phone}
                  onChange={onPhoneValueChanged}
                  variant="outlined"
                  label="Phone"
                /> */}
              </Grid>
              <Grid item xs={12}>
                <div className={addressInput ? classes.show : classes.hide}>
                  <GooglePlacesAutocomplete
                    apiKey={GOOGLE_PLACES_ID}
                    selectProps={{ 
                      id:"address",
                      placeholder: 'Address...',
                      name:"address",
                      onChange: (result) => {
                        console.log(result);
                        if(result)
                          onAddressValueChanged(result.value);
                      },
                    }}
                    onLoadFailed={(error) => (
                      console.error("Could not inject Google script", error)
                    )}
                  />
                </div>
                <div className={!addressInput ? classes.show : classes.hide}>
                  <FormControl fullWidth  variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-address">Address</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-address"
                      type='text'
                      name="address"
                      value={user.address}
                      onChange={onInputValueChanged}
                      //readOnly={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="edit address"
                            onClick={() => {setAddressInput(true);}}
                            edge="end"
                            label="Edit Address"
                          >
                            <EditLocationOutlined />
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </div>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  // error = {errorMessages.middleNameError.length > 0}
                  // helperText={errorMessages.middleNameError}
                  variant="outlined"
                  fullWidth
                  id="city"
                  label="City"
                  name="city"
                  autoComplete="city"
                  value={user.city}
                  onChange={onInputValueChanged}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  // error = {errorMessages.middleNameError.length > 0}
                  // helperText={errorMessages.middleNameError}
                  variant="outlined"
                  fullWidth
                  id="country"
                  label="Country"
                  name="country"
                  autoComplete="country"
                  value={user.country}
                  onChange={onInputValueChanged}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Collapse in={props.errorMessage.length > 0}>
                  <Alert severity="error">
                    {props.errorMessage}
                  </Alert>
                </Collapse>
                <Collapse in={props.successMessage.length > 0}>
                  <Alert severity="success">
                    {props.successMessage}
                  </Alert>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" > 
                  <Button 
                  variant="contained" 
                  color="primary" 
                  disabled={props.loading || isVerified} onClick={handleVerify}>
                    Resend Activation Email
                  </Button>
                  
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={props.loading}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
        <Box mt={5}> 
          <Copyright />
        </Box>
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = ({ authReducer,userReducer }) => {
  return {
    loading: authReducer.loading,
    isLoggedIn :authReducer.isLoggedIn,
    errorMessage: authReducer.errorMessage,
    successMessage: authReducer.successMessage,

    profile : userReducer.profile,
    isVerified:userReducer.isVerified
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onIsLogInAsync: () => dispatch(actionCreator.IsLogInAsync()),
    OnCleaUp: () => dispatch(actionCreator.ResetErrors()),

    onGetProfile: () => dispatch(actionCreator.GetProfileAsync()),
    UpdateProfileAsync: (user) => dispatch(actionCreator.UpdateProfileAsync(user)),
    OnVerifyUserAsync: (email) => dispatch(actionCreator.VerifyUserAsync(email)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile)