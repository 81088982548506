import React, { forwardRef, useEffect, useState } from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import * as actionCreator from "../../../store/action/index";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Table,
  TableBody,
  TableHead,
  Box,
  TableRow,
  TableCell,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getPlanLookUp } from "../../../utils/plans/planMethods";
import { getStatusLookUp } from "../../../utils/plans/statusMethods";
import DetailPanel from "./detailPanel";
import DetailDialog from "./DetailDialog";
import transitions from "@material-ui/core/styles/transitions";
import Spinner from "../../layout/Spinner/Spinner";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyItems: "center",
  },
  table: {
    minWidth: "50%",
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const UserTable = (props) => {
  const {
    userData,
    onUserSelect,
    plans,
    statusTypes,
    onGetPlans,
    fetchStatusList,
    updateUser,
    searchHandler,
    getCryptopiaDetail,
    finalSettlementCheck,
    loading,
  } = props;
  const [showAll, setShowAll] = useState(false);
  const [detailDialogState, setDetailDialogState] = useState(false);
  const [refreshData, setRefreshData] = useState(null);

  const classes = useStyles();
  useEffect(() => {
    if (!plans) onGetPlans();
    if (!statusTypes) fetchStatusList();
  }, []);

  const updateRowKyc = async (userData) => {
    await finalSettlementCheck(userData.email, null, searchHandler);
  };

  const OpenCryptopiaDialog = (rowData) => {
    let accounts = JSON.parse(rowData.cryptopia_accounts);
    setDetailDialogState(accounts);
  };

  useEffect(() => {
    if (refreshData) updateRowKyc(refreshData);
  }, [refreshData]);

  useEffect(() => {
    if (!loading) setRefreshData(null);
  }, [loading]);
  return (
    <>
      <DetailDialog
        status={detailDialogState}
        onDialogClose={(val) => setDetailDialogState(val)}
      />
      <MaterialTable
        icons={tableIcons}
        title="Users"
        columns={[
          {
            title: "",
            render: (rowData) => (
              <Box className="justifyCenter">
                {loading && rowData.email === refreshData?.email ? (
                  <Spinner size={16} margin={"unset"} />
                ) : (
                  <RefreshIcon
                    className="icon-size"
                    onClick={() => {
                      setRefreshData(rowData);
                    }}
                  />
                )}
              </Box>
            ),
            validate: (rowData) => Boolean(rowData.email),
          },
          {
            title: "Email",
            field: "email",
            render: (rowData) => (
              <Box onClick={() => OpenCryptopiaDialog(rowData)}>
                {rowData.email}
              </Box>
            ),
            validate: (rowData) => Boolean(rowData.email),
          },
          {
            title: "Email confirmed",
            field: "email_confirmed",
            render: (rowData) => (
              <Box onClick={() => OpenCryptopiaDialog(rowData)}>
                {rowData.email_confirmed ? "Yes" : "No"}
              </Box>
            ),
            lookup: { 0: "No", 1: "Yes" },
          },
          {
            title: "Name",
            field: "name",
            render: (rowData) => (
              <Box onClick={() => OpenCryptopiaDialog(rowData)}>
                {rowData.name}
              </Box>
            ),
            validate: (rowData) => Boolean(rowData.name),
          },
          {
            title: "Selected Plan",
            field: "plan",
            render: (rowData) => (
              <Box onClick={() => OpenCryptopiaDialog(rowData)}>
                {rowData.plan}
              </Box>
            ),
            lookup: getPlanLookUp(plans),
            validate: (rowData) => (!Boolean(rowData.cost_paid || rowData.cost_paid == 0) ||
              Boolean(rowData.plan))
            ,
          },
          {
            title: "Amount Paid",
            field: "cost_paid",
            render: (rowData) => (
              <Box onClick={() => OpenCryptopiaDialog(rowData)}>
                {rowData.cost_paid}
              </Box>
            ),
            type: "currency",
            currencySetting: {
              currencyCode: "EUR",
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            },
            validate: (rowData) => {
              return (
                (Boolean(!rowData.plan) ||
                  (rowData.cost_paid != null && rowData.cost_paid >= 0)) &&
                (rowData.cost_paid === null ||
                  (Number(rowData.cost_paid) >= 0))
              );
            },
          },
          // {
          //   title: "KYC Doc Count",
          //   field: "kyc_count",
          //   render: (rowData) => (
          //     <Box onClick={() => OpenCryptopiaDialog(rowData)}>
          //       {rowData.kyc_count}
          //     </Box>
          //   ),
          //   type: "numeric",
          //   editable: false,
          // },
          // {
          //   title: "Agreements Count",
          //   field: "aggrements_count",
          //   render: (rowData) => (
          //     <Box onClick={() => OpenCryptopiaDialog(rowData)}>
          //       {rowData.aggrements_count}
          //     </Box>
          //   ),
          //   type: "numeric",
          //   editable: false,
          // },
          {
            title: "Kyc Status",
            field: "kyc_status",
            render: (rowData) => (
              <Box onClick={() => OpenCryptopiaDialog(rowData)}>
                {rowData.kyc_status}
              </Box>
            ),
            lookup: getStatusLookUp(statusTypes),
          },
          {
            title: "User Status",
            field: "user_status",
            render: (rowData) => (
              <Box onClick={() => OpenCryptopiaDialog(rowData)}>
                {rowData.user_status ? "Exist" : "Not Found"}
              </Box>
            ),
            editable: false,
          },
          {
            title: "Cryptopia Email",
            field: "cryptopia_emails",
            render: (rowData) => (
              <Box onClick={() => OpenCryptopiaDialog(rowData)}>
                {rowData.cryptopia_emails}
              </Box>
            ),
            width: 150,
            validate: (rowData) => Boolean(rowData.cryptopia_emails),
          },
          {
            title: "Total Cryptopia Value in USD",
            field: "cryptopia_values",
            render: (rowData) => (
              <Box onClick={() => OpenCryptopiaDialog(rowData)}>
                {rowData.cryptopia_values}
              </Box>
            ),
            type: "currency",
            currencySetting: {
              currencyCode: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            },
            editable: false,
          },
        ]}
        editable={{
          onRowUpdate: async (newData, oldData) =>
            new Promise(async (resolve, reject) => {
              await updateUser(oldData, newData, searchHandler);
              resolve();
            }),
        }}
        data={userData}

        // detailPanel={[
        //   {
        //     tooltip: "Cryptopia Accounts",
        //     render: (rowData) => {
        //       let accounts = JSON.parse(rowData.cryptopia_accounts);
        //       console.log("ROw Data: ", rowData);
        //       return (
        //         <DetailPanel
        //           email={accounts && accounts[0] && accounts[0].email}
        //           accounts={accounts}
        //         />
        //       );
        //     },
        //   },
        // ]}
        onRowClick={(event, rowData, togglePanel) => {
          if (false) {
            let accounts = JSON.parse(rowData.cryptopia_accounts);
            setDetailDialogState(accounts);
          }
        }}
        onToggleDetailPanel={() => console.log("test toggle detail panel")}
        options={{
          pageSize: 50,
          pageSizeOptions: [50, 100, 200],
          filtering: true,
          selection: true,
          exportButton: true,
          exportAllData: showAll,
          doubleHorizontalScroll: true,
          emptyRowsWhenPaging: false,
          padding: "dense",
          headerStyle: {
            backgroundColor: "#3F51B5",
            color: "#FFF",
          },
          rowStyle: {
            backgroundColor: "#EEE",
          },
        }}
        onSelectionChange={(rows) => {
          onUserSelect(rows);
        }}
        actions={[
          {
            icon: () => {
              return (
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={<Checkbox color="primary" checked={showAll} />}
                      label="Export All"
                      labelPlacement="start"
                    />
                  </FormGroup>
                </FormControl>
              );
            },
            tooltip: "Export All",
            isFreeAction: true,
            onClick: (e) => {
              e.preventDefault();
              setShowAll(!showAll);
            },
          },
        ]}
      />
    </>
  );
};
const mapStateToProps = ({ userReducer, adminReducer, authReducer }) => {
  return {
    loading: authReducer.loading,
    plans: userReducer.plans,
    statusTypes: adminReducer.statusTypes,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    finalSettlementCheck: (email, transition, callback) =>
      dispatch(actionCreator.finalSettlementCheck(email, transition, callback)),
    onGetPlans: () => dispatch(actionCreator.GetPlansAsync()),
    fetchStatusList: () => dispatch(actionCreator.fetchStatusList()),
    updateUser: (oldData, newData, searchHandler) =>
      dispatch(actionCreator.updateUser(oldData, newData, searchHandler)),
    getCryptopiaDetail: (email) =>
      dispatch(actionCreator.getCryptopiaDetail(email)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
