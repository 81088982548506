import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import image1 from "../../asset/image/stepperImage/Step3.1.png";
import image2 from "../../asset/image/stepperImage/Step3.2.png";
import image3 from "../../asset/image/stepperImage/Step3.3.png";
import image4 from "../../asset/image/stepperImage/Step3.4.png";
import image5 from "../../asset/image/stepperImage/Step3.5.png";
import image6 from "../../asset/image/stepperImage/Step3.6.png";
import image7 from "../../asset/image/stepperImage/Step3.7.jpg";
import image8 from "../../asset/image/stepperImage/Step3.8.png";
import StepperImage from "./StepperImage";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
  },
  title: {
    fontSize: 20,
    marginBottom: theme.spacing(2),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const MakePaymentContent = ({ close, finalSettlementCheck, step, profile }) => {
  const classes = useStyles();
  const initialErrorMessage = {
    transactionId: "",
  };
  const [errorMessages, setErrorMessages] = React.useState(initialErrorMessage);
  const [value, setValue] = React.useState({
    transactionId: "",
  });

  const statusUpdate = (status) => {
    if (status) {
      close(false);
    } else {
      setErrorMessages({
        ...errorMessages,
        transactionId: "Transaction Id does not match.",
      });
    }
  };
  const registerHandler = (e) => {
    e.preventDefault();
    if (value.transactionId.length >= 5) {
      setErrorMessages(initialErrorMessage);
      finalSettlementCheck(profile.email, value.transactionId, statusUpdate);
    } else {
      setErrorMessages({
        ...errorMessages,
        transactionId: value?.transactionId
          ? "Transaction Id must be of alteast 5 characters"
          : "Transactio Id Needed!!",
      });
    }
  };
  React.useEffect(() => {
    setErrorMessages(initialErrorMessage);
  }, [value]);
  return (
    <>
      <Box>
        <form className={classes.form} onSubmit={registerHandler} method="post">
          <Grid container spacing={1}>
            {/* <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h5"
                className={clsx(classes.title, "justifyCenter")}
              >
                Enter Transaction Id
              </Typography>
            </Grid> */}
            <Grid item xs={0} sm={3}></Grid>
            <Grid item xs={12} sm={6} className={clsx("justifyCenter")}>
              <TextField
                error={errorMessages.transactionId.length > 0}
                helperText={errorMessages.transactionId}
                autoComplete="transactionId"
                name="transactionId"
                variant="outlined"
                required
                fullWidth
                id="transactionId"
                label="Transaction Id"
                autoFocus
                size="small"
                value={value.transactionId}
                onChange={(e) => {
                  setValue({
                    ...value,
                    transactionId: e?.target?.value?.replaceAll(" ", ""),
                  });
                }}
              />
            </Grid>
            <Grid item xs={0} sm={3}></Grid>
          </Grid>
          <Grid container spacing={1} className={clsx("justifyCenter")}>
            <Grid item xs={12} sm={6} className={clsx("justifyCenter")}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
        <Box className="list-template">
          <ul>
            <li>
              <strong>You're almost there!</strong> Now, for the payment of your
              chosen plan.
            </li>
            <li>
              On your Cogito account click <strong>“banking”</strong> (it’s the
              greek structure-shaped icon on the sidebar on the left).
            </li>
            <li>
              Click <strong>“Pay”</strong>.
            </li>
            <Grid container spacing={1}>
              <Grid item sm={3} xs={0}></Grid>
              <Grid item sm={6} xs={12}>
                <StepperImage image={image1} />
              </Grid>
              <Grid item sm={3} xs={0}></Grid>
            </Grid>
            <li>
              Type <strong>“cryptopiarescue”</strong> in the{" "}
              <strong>“Pay To”</strong> section.
            </li>
            <li>
              And then type the amount of the plan you are paying for in the{" "}
              <strong>“COG amount”</strong> section.
            </li>
            <li>
              Once you’ve filled up the <strong>“Pay To”</strong> and{" "}
              <strong>“COG amount”</strong> sections. Click the{" "}
              <strong>“Pay”</strong> button.
            </li>
            <Grid container spacing={1}>
              <Grid item sm={3} xs={0}></Grid>
              <Grid item sm={6} xs={12}>
                <StepperImage image={image2} />
              </Grid>
              <Grid item sm={3} xs={0}></Grid>
            </Grid>
            <li>
              Next, put your pin in the <strong>“Pin”</strong> section then
              click the <strong>“Pay”</strong> button.{" "}
            </li>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <StepperImage image={image3} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <StepperImage image={image4} />
              </Grid>
            </Grid>
            <li>
              <strong>Your almost done!</strong> Now go back to you{" "}
              <strong>“transactions ”</strong> (it’s the two arrows facing
              oppositely to each other icon on the sidebar on the left).
            </li>
            <Grid container spacing={1}>
              <Grid item sm={3} xs={0}></Grid>
              <Grid item sm={6} xs={12}>
                <StepperImage image={image5} />
              </Grid>
              <Grid item sm={3} xs={0}></Grid>
            </Grid>
            <li>
              Click your <strong>“cryptopiarescue”</strong> transaction and copy
              the <strong>“transaction Id”</strong>.
            </li>
            <Grid container spacing={1}>
              <Grid item sm={3} xs={0}></Grid>
              <Grid item sm={6} xs={12}>
                <StepperImage image={image6} />
              </Grid>
              <Grid item sm={3} xs={0}></Grid>
            </Grid>
            <li>
              Now go back to your “cryptopiarescue account”. Go to this link{" "}
              <a
                href="https://membership.cryptopiarescue.com/login"
                target={"_blank"}
              >
                https://membership.cryptopiarescue.com/login
              </a>
            </li>
            <Grid container spacing={1}>
              <Grid item sm={3} xs={0}></Grid>
              <Grid item sm={6} xs={12}>
                <StepperImage image={image7} />
              </Grid>
              <Grid item sm={3} xs={0}></Grid>
            </Grid>
            <li>
              Click <strong>“details”</strong> on the{" "}
              <strong>“Make Payment”</strong> section and paste the{" "}
              <strong>“transaction Id”</strong> in the{" "}
              <strong>“transaction Id”</strong> section.{" "}
            </li>
            <li>
              Then click <strong>“Submit”</strong>.
            </li>
            <Grid container spacing={1}>
              <Grid item sm={3} xs={0}></Grid>
              <Grid item sm={6} xs={12}>
                <StepperImage image={image8} />
              </Grid>
              <Grid item sm={3} xs={0}></Grid>
            </Grid>
            <li>
              <strong>Congratulations!</strong> You have now officially
              completed your registration! We look forward to serving and
              working with you.
            </li>
          </ul>
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = ({ finalSettlement, userReducer }) => {
  return {
    step: finalSettlement.step,
    profile: userReducer.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    finalSettlementCheck: (email, transactionId, callback) =>
      dispatch(
        actionCreator.finalSettlementCheck(email, transactionId, callback)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MakePaymentContent);
