import React, { useState, useEffect } from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import clsx from "clsx";
import { filesToBlob, fileToBlob } from "../../../utils/blob/fileToBlob";
import * as actionCreator from "../../../store/action/index";
import { connect } from "react-redux";
import { base64toBlob } from "../../../utils/blob/base64ToBlob";

const useStyles = makeStyles((theme) => ({
  justifyStart: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  marginLeft: {
    marginLeft: 10,
  },
}));

const AddDocs = ({
  selectId,
  setBlob,
  fileSize,
  cryptopiaId,
  getUploadedFiles,
  userFiles,
}) => {
  const id = `select-file-${selectId}`;
  const classes = useStyles();
  const [files, setFiles] = useState([]);

  const selectImage = () => {
    let fileContainer = document.getElementById(id);
    fileContainer.click();
  };

  const getBlob = async (files) => {
    // let blob = await filesToBlob(files);
    let blob = files;
    if (setBlob) {
      let newFileList = [];
      let newBlobList = [];
      files.map((file, index) => {
        if (!file.id) {
          newFileList.push(file?.name);
          newBlobList.push(blob[index]);
        }
      });
      
      setBlob((data) => ({
        ...data,
        // file: [...files],
        file: [...newBlobList],
        fileName: [...newFileList],
      }));
    }
  };
  useEffect(() => {
    files.length && getBlob(files);
  }, [files]);

  useEffect(() => {
    if (cryptopiaId) getUploadedFiles(cryptopiaId);
  }, [cryptopiaId]);

  useEffect(() => {
    console.log("userFiles: ", userFiles);
    if (cryptopiaId && userFiles && userFiles.length)
      setFiles([
        ...userFiles.map((file) => ({
          ...file,
          name: file.cryptopia_doc_name,
        })),
      ]);
    else setFiles([]);
  }, [userFiles]);
  return (
    <Box>
      <Box>
        <Button onClick={selectImage}>
          <Typography className={classes.justifyStart}>
            Add documents{" "}
            <span style={{ fontSize: 10, textTransform: "none" }}>
              ({`Maximum ${Number((fileSize / (1024 * 1024)).toFixed(2))}MB`})
            </span>
          </Typography>
          <Add className={classes.marginLeft} />
        </Button>
        <input
          id={id}
          type="file"
          // accept="image/png, image/jpeg, image/jpg, application/pdf"
          accept="application/pdf"
          style={{ display: "none" }}
          onChange={(e) => {
            if (e?.target?.files && fileSize >= e?.target?.files[0]?.size) {
              setFiles([...files, ...e?.target?.files]);
              document.getElementById(id).value = null;
            }
          }}
        />
      </Box>
      .
      <Box className={classes.marginLeft}>
        {files && files.length ? (
          files.map((file, index) => {
            return (
              <Box
                className={classes.justifyStart}
                key={`${index}-${file.name}`}
              >
                <Typography>{index + 1}. </Typography>
                <Typography>{file.name || "Unnamed"}</Typography>
                <Button
                  className={clsx(classes.marginLeft, classes.justifyStart)}
                >
                  <Close
                    onClick={() => {
                      let tempFiles = files;
                      tempFiles.splice(index, 1);
                      setFiles([...tempFiles]);
                      if (cryptopiaId && file.id) {
                        setBlob((data) => ({
                          ...data,
                          deleteFile: [...(data.deleteFile || []), file.id],
                        }));
                      }
                    }}
                  />
                </Button>
              </Box>
            );
          })
        ) : (
          <Typography>No file selected</Typography>
        )}
      </Box>
    </Box>
  );
};
AddDocs.defaultProps = {
  fileSize: 5 * 1024 * 1024,
};
const mapStateToProps = ({ userReducer }) => {
  return {
    userFiles: userReducer.cryptopiaFiles,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUploadedFiles: (id) => dispatch(actionCreator.getUploadedFiles(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddDocs);
